.mode-active-search {
    opacity: 0.2;
    pointer-events: none;
}

.custom-button {
    display: block;
    background-color: transparent;
    border: none;
    color: none;
    width: 100%;
}

.tittle-option-content-movile {
    text-align: left;
    text-transform: uppercase;
    color: #727272;
    font-family: Str-Headline-Regular, sans-serif;
    font-size: 12px;
    border-bottom: 1px solid #CEDEE7;
    padding-bottom: 9px;
    letter-spacing: 1.2px;
}

.custom-button-icon {
    background-color: transparent;
    border: none;
    color: none;

}
