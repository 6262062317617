.download-icon {
    
  display: flex;
  font-family: "Str-Microtext-Regular", sans-serif;
  text-decoration-skip-ink: none;
  color: #444444;
  margin-bottom: 26px;
  &__icon {
      margin-right: 8px;
  }

  span {
      cursor: pointer;
      color: #444444;
  }
}

.download-icon:hover {
  color: #444444;
}