.results-table__ResultadosSimulacion{
  width: 100%;
    box-shadow: 1px 1px 7px 1px rgba(165, 164, 164, 0.2);
}

.container__ResultadosSimulacion{
  padding-top: 60px;
  width: 608px;
  margin: 0 auto;
}
@media only screen and (max-width: 585px) {
  .container__ResultadosSimulacion{
    width: 90%;
  }
}