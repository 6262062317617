.btn-danger {
  background-color: #ec0000;
  border: none !important;
}

.linkDescarga {
  color: #cc0000;
  text-decoration: none;
  background: white;
  border-radius: 24px;
  padding: 12px 16px;
  height: 48px;
  width: 199px;
  border: 1px solid #EC0000;
  text-align: center;
}

.linkDescarga:link, :visited, :hover, :active{
  text-decoration: none;
}

.linkUnderline {
  color: #ec0000;
  font-family: Str-Headline-Bold, sans-serif;
  text-decoration: underline;
}
