.banner-rc {
    background-color: #F5F9FB;
    padding         : 32px 16px;
    text-align      : center;
    width           : 100%;
    display         : flex;
    justify-content : center;

    img {
        width : 240px;
        height: 240px;
    }

    &_container {
        max-width: 480px;
    }

    &_title {
        color      : #222222;
        font-size  : 28px;
        font-weight: 700;
        line-height: 36px;
        font-family: Str-Headline-Bold, sans-serif;
        width: 100%;
        max-width: 250px;
    }

    &_description {
        color        : #222222;
        font-weight  : 400;
        font-size    : 16px;
        line-height  : 24px;
        font-family  : "Str-Microtext-Regular";
        margin-top   : 16px;
        margin-bottom: 24px;
    }

    &_container-tl {
        display: flex;
        justify-content: center;
    }

    @media (min-width: 768px) {
        padding: 48px 24px;

        &_container {
            max-width: 360px;
        }

        img {
            width : 360px;
            height: 360px;
        }

        &_title {
            font-size: 42px;
            line-height: 52px;
            max-width: 350px;
        }

        &_description {
            margin-bottom: 40px;
        }
    }

    @media (min-width: 1200px) {
        padding: 64px 24px;

        &_container {
            max-width: 400px;
        }

        img {
            width : 400px;
            height: 400px;
        }

        &_description {
            padding: 0 8px;
        }
    }

    @media (min-width: 1440px) {
        max-width: 590px;
    }
}

.container-form-rc {
    text-align: center;

    &_container-tl {
        display: flex;
        justify-content: center;
    }

    &_title {
        font-size    : 24px;
        font-weight  : 700;
        line-height  : 32px;
        font-family  : Str-Headline-Bold, sans-serif;
        color        : #222222;
        margin-bottom: 32px;
        display      : block;
        width: 100%;
        max-width: 250px;
    }

    &_content {
        display      : flex;
        flex-wrap    : wrap;
        gap          : 12px;
        margin-bottom: 32px;
    }

    .form-check-label {
        text-align: left;
    }

    @media (min-width: 768px) {
        &_title {
            font-size: 28px;
            max-width: none;
        }
    }

    @media (min-width: 1200px) {
        &_title {
            max-width: 350px;
        }
    }
}

.container-full-content {
    width          : 100%;
    display        : flex;
    justify-content: center;
    padding        : 32px 16px;

    &_container {
        width    : 100%;
        max-width: 480px;
    }

    &_thank_you {
        text-align: center;
        padding: 24px;
        margin: 64px 0;

        span {
            display: block;
            font-size  : 24px;
            line-height: 32px;
            font-weight: 700;
            font-family: Str-Headline-Bold, sans-serif;
            color: #222222;
            margin-bottom: 8px;
            margin-top: 20px;
        }

        p {
            font-size  : 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: "Str-Microtext-Regular";
            color: #222222;
            margin-bottom: 32px;
        }
    }

    &_action {
        width: 100%;

        button {
            border-radius: 50px;
            font-family  : "Str-Microtext-Bold";
            font-size    : 16px;
            height       : 48px;
            height       : 48;
            text-align   : center;
            margin-top   : 16px;
            margin-bottom: 0;
            width: 100;
            max-width: 151px;
            box-shadow: 0px 1px 6px 0px #A3A3A366;
        }
    }

    @media (min-width: 768px) {
        padding: 48px 16px;

        &_container {
            max-width: 580px;
        }
    }

    @media (min-width: 1200px) {
        padding: 64px 16px;
        align-items: center;

        &_container {
            max-width: 480px;
        }
    }
}

.container-action button {
    width    : 100%;
    max-width: 343px;
    border-radius: 32px;
    font-family: "Str-Microtext-Bold";
    font-size: "16px";
    height: 48px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 0;
    box-shadow: 0px 1px 6px 0px #A3A3A366;

    @media (min-width: 768px) {
        max-width: 200px;
    }
}

.span-check {
    font-family: "Str-Microtext-Regular";
    line-height: 24px;
}

.terms-request {
    padding    : 32px 16px;
    color      : #727272;
    font-size  : 14px;
    font-family: "Str-Microtext-Regular";
    line-height: 20px;
    text-align: justify;

    @media (min-width: 768px) {
        padding: 32px 24px;
    }
}

.row-rc {
    width    : 100%;
    display  : flex;
    flex-wrap: wrap;
    gap      : 12px;

    @media (min-width: 768px) {
        flex-wrap: initial;
    }

    @media (min-width: 1200px) {
        flex-wrap: wrap;
    }
}

.full-container {
    display        : flex;
    justify-content: center;

    &_container {
        width    : 100%;
        max-width: 1440px;
    }
}

.first-content {
    @media (min-width: 1200px) {
        display      : flex;
        margin-bottom: 32px;
    }
}

.link-rc {
    color: #23779A;
}