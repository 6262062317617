.section-protect-cards {
	padding: 48px 0px;
	color: #222222;
	
	@media (min-width: 768px) {
		padding: 64px 0px;
	}

	&--title {
		font-size: 28px;
		font-family: "Str-Headline-Bold", sans-serif;
		line-height: 48px;

		@media (min-width: 768px) {
			font-size: 32px;
		}
	}

	&--description {
		font-family: "Str-Microtext-Regular", sans-serif;
		line-height: 16px;
		margin: 24px 0px;
	}
}

.cards-product {
	margin-top: 48px;
}