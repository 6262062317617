.box-accordion {
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	gap: 10px;

	&__item {
		box-sizing: border-box;
		flex-direction: column;
		border: 1px solid #767676 !important;
		border-radius: 8px !important;

		&__header:hover {
			background: #F6F6F6;
			border-radius: 8px !important;
		}

		&__body {
			padding: 8px 20px 24px;
			gap: 24px;
			background: #F5FAFB;

			a {
				display: block;
				color: #257FA4;
				width: max-content;
				margin-bottom: 15px;
			}

			&__arrow {
				padding-left: 10px;
			}

			&__list {

				&__item {
					display: flex;
					margin-bottom: 8px;
				}

				&__item::before {
					content: url("../../../../../public/icons/chevron-right.svg");
					height: 20px;
					margin-right: 8px;
					margin-top: 2px;
				}

			}

			&__sub-list {
				padding-left: 32px;

				&__sub-item {
					display: flex;
					margin-bottom: 8px;
				}


				&__sub-item::before {
					content: url("../../../../../public/icons/chevron-right-sm.svg");
					height: 20px;
					margin-right: 8px;
					margin-top: 2px;
				}
			}
		}
	}

	.accordion-body {
		border-radius: 8px;
	}
	
	.accordion-item .accordion-button {
		background: transparent;
	}
	
	.accordion-button::after {
		background-image: url("../../../../../public/icons/chevron-down.svg") !important;
	}
	
	.accordion-button:not(.collapsed)::after {
		background-image: url("../../../../../public/icons/chevron-up.svg") !important;
	}
	
	.accordion-button:not(.collapsed) {
		color: black;
		background-color: transparent !important;
	
	}
	
}



ul {
	padding: 0px;
}
