
.nav-icons {
  display: none !important;
}
.img {
  width: 25px;
  height: 25px;
  margin-left: 3px;
}
.in-circle {
  position: relative;
  margin: auto;
}

.surgir-01 {
  content: url("../../../assets/icons/surgir-01.svg");
}
.shape {
  content: url("../../../assets/icons/shape.svg");
}
.investment {
  content: url("../../../assets/icons/investment.svg");
}
.financing {
  content: url("../../../assets/icons/financing.svg");
}
.civil-liability {
  content: url("../../../assets/icons/civil-liability.svg");
}
.payment-dollar {
  content: url("../../../assets/icons/payment-dollar.svg");
}
.call-support {
  content: url("../../../assets/icons/call-support.svg");
}
.deal-handshake {
  content: url("../../../assets/icons/deal-handshake.svg");
}

.navbar-surgir {
  background-color: #ffffff;
  padding: 0px 0%;
  z-index: 4;
  position: relative !important;
  width: 100%;
  display: flex;
    flex-wrap: wrap;
}

.navbar-shadow {
  box-shadow: 0px 4px 8px #1d252d29;
  margin-top: 0 !important;
}

.navbar-surgir.navbar-shadow {
  top: 0;
  transition: top 0.1s ease;
}

.navbar-surgir:not(.navbar-shadow) {
  top: 34px;
}
.fixer-display{
  padding-left: 276px;
}
.logo {
  max-height: "30px";
  padding-left: "10px";
  margin-right: "5em";
}

.nav-mobile-content {
  background-color: #ffffff;
  position: initial;
  top: 82px;
  left: 0;
  width: 100%;
  transition: all .3s ease-in-out;
  overflow: auto;
}

.nav-mobile-fixed {
  position: fixed !important;
}

.open-nav {
  max-height: 500px;
}



.nav-movile {
  display: none;
}
.nav-desktop {
  display: flex;
  transition: all 0.2s;
}
.navbar-light .navbar-nav .nav-link {
  color: #444444 !important;
  padding: 10px 20px !important;
  opacity: 0.9;
  font-family: Str-Headline-Bold, sans-serif;
  font-size: 12pt;
}

.navbar ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  font-family: Str-Headline-Bold, sans-serif;
  font-size: 12pt;
}

.navbar a {
  text-decoration: none;
  color: #444444;
  font-family: Str-Headline-Bold, sans-serif;
  font-size: 12pt;
  padding: 16px;
  display: block;
}

.dropdown {
  position: relative;
  a {
    display: flex;
  }
  .option-card-li {
    display: block;
  }
  img {
    margin: 6px 5px 4px;
    transition: transform 0.5s ease;
  }
}
.dropdown-content-body {
  display: flex;
  justify-content: center;
  background: #fff;
}

.backdrop {
  background: rgba(34, 34, 34, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 370vh;
  z-index: -1;
  display: none;
}

.dropdown:hover .backdrop {
  display: block;
}
.dropdown:not(:hover) .backdrop {
  display: none;
}
.dropdown-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0;
  transform-origin: top;
  z-index: -5;
}

.transition-nav {
  transition: all 600ms cubic-bezier(0.42, 0, 0.58, 1) !important;
}

.dropdown-content.visible {
  opacity: 1;
  transform: translateY(0);
  top: 82px;
}
.non-hovered {
  a {
    color: #222222;
    opacity: 0.2;
  }
}
.non-clicked {
  a {
    color: rgba(34, 34, 34, 0.5);
  }
}
.hovered {
  a {
    color: #222222;
  }
}
.clicked {
  a {
    color: #222222;
  }
}
.inactive-navbar {
  a {
    color: #222222;
  }
}
.spacerDiv {
  width: 0.5%;
}

.dropdown:hover .arrow_up {
  transform: rotate(180deg);
}

.rotate-icon {
  transform: rotate(0) !important;
}

.SearchIcon {
  margin: 0 25px 0 35px;
  width: 20px;
  height: 20px;
  position: relative;
  top: 4px;
}
.full-width-content {
  width: 1220px;
  background-color: #fff;
  display: flex;
  .img-card {
    width: 260px;
    display: block;
    margin: 32px 0 42px 16px;
    padding: 24px 20px;
    border-radius: 8px;
    gap: 24px;
    background-color: rgba(245, 249, 251, 1);

    .img-text {
      font-family: Str-Headline-Bold, sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      padding-right: 20px;
      text-align: left;
    }
  }
  .option-content {
    opacity: 0;
    transition: opacity 0.5s linear 0s;

    .option-card {
      margin: 12px 16px ;

      .tittle-option-content {
        margin: 24px 12px;
        display: flex;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        height: 40px;
        border-bottom: 1px solid #cedee7;
        img {
          margin: 2px 8px 4px;
        }
      }
      .ul-card {
        display: flex !important;
        flex-direction: column !important;
        li {
          padding: 12px 0 7px;
        }
        li:hover {
          .a-title {
            color: #ec0000 !important;
          }
        }
        .a-title {
          font-size: 14px;
          font-family: Str-Headline-Bold, sans-serif;
          line-height: 10px;
          text-align: left;
          color: #222222;
        }
        .a-description {
          font-size: 14px;
          font-family: Str-Microtext-Regular, sans-serif;
          line-height: 10px;
          text-align: left;
          color: #727272;
        }
        a {
          padding: 0;
        }
      }
    }
  }
  .option-content ul {
    margin: 10px 14px;
  }
}
.dropdown:hover .option-content {
  opacity: 1;
}

.option .block {
  display: block !important;
}
.no-display {
  display: none !important;
}

.content-responsiv {
  display: flex;
  justify-content: center;
  width: 100%;
}
.content-responsive {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  width: 1400px;
  margin: auto;
  align-content: center;
  padding: 12px 16px;
}
.container-search-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    content: url("../../../assets/icons/search-icon.svg");
  }

  .close-ic img {
    content: url("../../../assets/icons/close-icon.svg");
  }

  .burger img {
    content: url("../../../assets/icons/burger.svg");
  }

}
.no-show {
  display: none;
}

.scroll-fixer-mobile {
  top: 47px !important;
}
.scroll-fixer-desktop {
  margin-top: 0;
  opacity: 0;
  transition: top 0.2s ease;
  top: 48px !important;
}
.option-content-movile {
  transition: opacity 1s cubic-bezier(0.42, 0, 0.58, 1),
  max-height 300ms ease-out;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  display: none;
}
.option-content-movile.block {
  display: block;
}
.container-search-icon:hover {
  background: #2222220f;
}

// Variables para los puntos de ruptura
$mobile: 320px;
$tablet1: 376px;
$tablet2: 768px;
$tablet3: 1199px;
$descktop4: 1440px;
$descktop5: 1920px;

// Media queries globales
$mq-tablet1: "only screen and (min-width: #{$mobile }) and (max-width: #{$tablet1})";
$mq-tablet2: "only screen and (min-width: #{$tablet1 + 1 }) and (max-width: #{$tablet2})";
$mq-tablet3: "only screen and (min-width: #{$tablet2 + 1}) and (max-width: #{$tablet3})";
$mq-descktop4: "only screen and (min-width: #{$tablet3 + 1}) and (max-width: #{$descktop4})";
$mq-descktop5: "only screen and (min-width: #{$descktop4 + 1})";
@media #{$mq-descktop5} {
  .full-width-content {
    width: 1400px;
  }

  .option-card {
    min-width: 345.33px;
  }
  .nav-mobile-content {
    display: none !important;
  }
  .content-responsive {
    padding: 0px;
    margin-top: 8px;
  }
}
@media #{$mq-descktop4} {

  .option-card {
    min-width: 280px;
  }
  .nav-mobile-content {
    display: none !important;
  }
  .content-responsive {
    padding: 0 16px 0 0;
  }
}
@media #{$mq-tablet3} {

  .nav-desktop {
    display: none !important;
  }
  .nav-icons {
    display: block !important;
  }
  .nav-icons-movile {
    display: none !important;
  }
  .spacer {
    width: 90%;
  }
  .nav-movile {
    display: block;
  }
  .ul-card {
    a {
      padding: 0;
    }
    li:hover {
      .a-title {
        color: #ec0000 !important;
      }
    }
  }
  .dropdown-movile {
    a {
      display: flex;
      justify-content: space-between;
    }
  }
  .option-card {
    padding: 0px 16px;
  }
  .navbar ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: block;
    font-family: "Str-Microtext-Regular", sans-serif;
    font-size: 12pt;
  }
  .tittle-option-content {
    font-family: Str-Headline-Regular, sans-serif;
    border-bottom: 1px solid #cedee7;
    font-size: 18px;
  }
  .navbar-surgir {
    .navbar a {
      padding: 0;
    }
  }
}

@media #{$mq-tablet2} {

  .nav-icons {
    margin-right: 10px;
    display: block !important;
  }
  .nav-icons-movile {
    display: none !important;
  }
  .spacer {
    width: 90%;
  }
  .logo {
    max-height: "30px";
    padding-left: "10px";
  }
  .nav-desktop {
    display: none !important;
  }
  .nav-movile {
    display: block;
  }
  .navbar ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: block;
    font-family: "Str-Microtext-Regular", sans-serif;
    font-size: 12pt;
  }
  .tittle-option-content {
    border-bottom: 1px solid #cedee7;
  }
  .ul-card {
    a {
      padding: 0;
    }
    li:hover {
      .a-title {
        color: #ec0000 !important;
      }
    }
  }
  .dropdown-movile {
    a {
      display: flex;
      justify-content: space-between;
    }
  }
  .option-card {
    padding: 0px 16px;
  }
  .content-responsive {
    padding: 0 5px 0 0px;
  }
}
@media #{$mq-tablet1} {

  .nav-icons {
    margin-right: 10px;
    display: block !important;
  }
  .nav-icons-movile {
    display: none !important;
  }
  .spacer {
    width: 90%;
  }
  .logo {
    max-height: "30px";
    padding-left: "10px";
  }
  .nav-desktop {
    display: none !important;
  }
  .nav-movile {
    display: block;
  }
  .navbar ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: block;
    font-family: "Str-Microtext-Regular", sans-serif;
    font-size: 12pt;
  }
  .ul-card {
    a {
      padding: 0;
    }
    li:hover {
      .a-title {
        color: #ec0000 !important;
      }
    }
  }
  .dropdown-movile {
    a {
      display: flex;
      justify-content: space-between;
    }
  }
  .option-card {
    padding: 0px 16px;
  }
  .navbar-surgir {
    .navbar a {
      padding: 0;
    }
  }

  .content-responsive {
    padding: 10px 5px 10px 16px;
  }
}
