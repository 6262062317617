$primary-dark-red-990000: #900;
$primary-ruby-red-a-60000: #a60000;
$primary-boston-red-cc-0000: #c00;
$primary-santander-red-ec-0000: #ec0000;
$grey-light-grey-cccccc: #ccc;
$grey-medium-grey-767676: #767676;
$grey-neutral-05-f-6-f-6-f-6: #f6f6f6;
$grey-dark-grey-444444: #444;
$primary-athracite-1-d-252-d: #1d252d;
$primary-white-ffffff: #fff;
$primary-dark-sky-9-bc-3-d-3: #9bc3d3;
$primary-accesible-sky-257-fa-4: #257fa4;
$primary-medium-sky-c-8-dee-8: #cbdee8;
$primary-light-sky-deedf-2: #deedf2;
$primary-sky-50-eff-6-f-9: #eff6f9;
$secondary-background-f-5-f-9-fb: #f5f9fb;
$secondary-yellow-ffcc-33: #fc3;
$secondary-yellow-10-fffaeb: #fffaeb;
$secondary-green-success: #f0f8f0;
$secondary-accesible-yellow-946-f-00: #946f00;
$secondary-green-63-ba-68: #63ba68;
$secondary-accesible-green-008437: #3a8340;
$primary-red-10-fee-5-e-5: #fff1f1;
$secondary-turquoise-10-e-8-fcfc: #e8fcfc;
$secondary-turquoise-1-bb-3-bc: #1bb3bc;
$secondary-accesible-turquoise-137-e-84: #137e84;
$secondary-blue-80-5-c-85-ff: #5c85ff;
$seconday-blue-10-ebf-0-ff: #ebf0ff;
$secondary-accesible-blue-0032-e-6: #0032e6;
$secondary-purple-10-f-6-ebf-0: #f6ebf0;
$secondary-accesible-purple-7-c-1844: #7c1844;
$secondary-purple-9-e-3667: #9e3667;
$secondary-background-f-5-f-9-fb: #f5f9fb;
$secondary-yellow-ffcc-33: #fc3;
$primary-accesible-sky-257-fa-4: #257fa4;
$secondary-yellow-10-fffaeb: #fffaeb;
$secondary-accesible-turquoise-137-e-84: #137e84;
$primary-sky-50-eff-6-f-9: #eff6f9;
$primary-light-sky-deedf-2: #deedf2;
$primary-white-ffffff: #fff;
$primary-ruby-red-a-60000: #a60000;
$grey-neutral-05-f-6-f-6-f-6: #f6f6f6;
$primary-dark-red-990000: #900;
$primary-burnt-red-420716: #420716;
$primary-red-10-fee-5-e-5: #fee5e5;
$primary-athracite-1-d-252-d: #1d252d;
$secondary-purple-9-e-3667: #9e3667;
$secondary-turquoise-1-bb-3-bc: #1bb3bc;
$secondary-accesible-purple-7-c-1844: #7c1844;
$primary-medium-sky-c-8-dee-8: #cbdee8;
$secondary-turquoise-50-8-dd-9-de: #8dd9de;
$primary-dark-sky-9-bc-3-d-3: #9bc3d3;
$grey-dark-grey-444444: #444;
$secondary-purple-10-f-6-ebf-0: #f6ebf0;
$secondary-turquoise-10-e-8-fcfc: #e8fcfc;
$secondary-accesible-yellow-946-f-00: #946f00;
$grey-medium-grey-767676: #767676;
$secondary-green-63-ba-68: #63ba68;
$secondary-yellow-20-fff-5-d-7: #fff5d7;
$grey-light-grey-cccccc: #ccc;
$secondary-accesible-green-008437: #008437;
$secondary-blue-3366-ff: #36f;
$black-24: rgba(0, 0, 0, 0.24);