@import "../../../breakpoints";

.carousel {
	&__card {
    color: #444444;
    font-size: 16px;
    font-family: "Str-Microtext-Regular", sans-serif;
    margin: auto;
    height: auto;
    padding: 40px 0px;
    width: 270px;

    &__description {
      p {
        font-size: 16px;
        color: #444444;

        @media #{$mq-tablet3} {
          font-size: 14px;
        }

        @media #{$mq-tablet2} {
          font-size: 14px;
        }

      }
    }

    h4 {
      margin-top: 12px;
      color: #333333;
      font-family: "Str-Headline-Bold", sans-serif;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: #EC0000 !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    padding: 8px 16px;
    border-radius: 100%;
    border: 1px solid #EC0000;
    width: 48px !important;
    height: 48px !important;
  }

  .swiper-button-prev {
    background-image: url("../../../assets/icons/chevron-left-red.svg");
  }

  .swiper-button-next {
    background-image: url("../../../assets/icons/chevron-right-red.svg");
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    content: "" !important;
  }


}

.span-alert {
  color: #EC0000;
  font-family: "Str-Headline-Bold", sans-serif;
}

.span-link {
  color: #EC0000;
  font-family: "Str-Headline-Bold", sans-serif;
  text-decoration: underline
}