.linea {
  color: #FFFFFF;
}

.card2-style {
  width: 99%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #9E3667;
  background-clip: border-box;
  border-radius: 20px;
  padding: 32px 60px 48px 32px;
  margin-top: 24px;

}

.card3-style {
  width: 99%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #257FA4;
  background-clip: border-box;
  border-radius: 20px;
  padding: 32px 60px 48px 32px;
  margin-top: 24px;

}

.card4-style {
  width: 99%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #f30000;
  background-clip: border-box;
  border-radius: 20px;
  padding: 32px 60px 48px 32px;
  margin-top: 24px;
}

.titulo {
  font-family: "Str-Headline-Bold", sans-serif;
  font-size: 28px;
  font-style: normal;
  line-height: 28px;
  text-align: left;
  color: #FFFFFF;
  display: flex;
}

.donde-estamos-container {
  display: flex;
  justify-content: space-between;
}

.donde-estamos-container-office {
  width: 418px;
  margin-right: 120px;
}

@media screen and (max-width: 575px) {
  .card1-style {
    width: 100%;
    height: 100%;
    display: flex;
    background: #CC0000;
    border-radius: 20px;
    padding: 32px 32px 38px 32px;

  }

  .card2-style {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #9E3667;
    background-clip: border-box;
    border-radius: 20px;
    padding: 32px 40px 48px 32px;
  }

  .card3-style {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #257FA4;
    background-clip: border-box;
    border-radius: 20px;
    padding: 32px 40px 48px 32px;
  }

  .titulo {
    font-family: "Str-Headline-Bold", sans-serif;
    font-size: 24px;
    font-style: normal;
    line-height: 28px;
    text-align: left;
    color: #FFFFFF;
    display: flex;
  }

  .donde-estamos-container {
    display: block;
  }

  .donde-estamos-container-office {
    width: 100%;
    margin-right: 12px;
  }

}

@media screen and (min-width: 576px) and (max-width: 1199px) {

  .card2-style {
    width: 99%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #9E3667;
    background-clip: border-box;
    border-radius: 20px;
    padding: 32px 60px 48px 32px;
    margin-top: 24px;
  }

  .card3-style {
    width: 99%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #257FA4;
    background-clip: border-box;
    border-radius: 20px;
    padding: 32px 60px 48px 32px;
    margin-top: 24px;
  }

  .titulo {
    font-family: "Str-Headline-Bold", sans-serif;
    font-size: 24px;
    font-style: normal;
    line-height: 28px;
    text-align: left;
    color: #FFFFFF;
    display: flex;
  }

  .donde-estamos-container {
    display: block;
  }

}

.texto {

  font-family: "Str-Headline-Bold", sans-serif;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  line-height: 24px;
  text-align: left;
  color: #FFFFFF;
  display: flex;

}

.subtext {
  font-family: "Str-Microtext-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  color: #FFFFFF;
  flex-wrap: wrap;
  display: flex;
}