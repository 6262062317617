.container-form-personal {
  width: 100%;
  padding: 0 0.25rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 24px;
  .css-16awh2u-MuiAutocomplete-root {
    width: 100%;
    margin: 0 !important;
  }
  .css-1d1r5q-MuiFormHelperText-root.Mui-error{
    margin-top: -12px !important;
  }
}

.sub-title-span {
  font-family: "Str-Headline-Bold", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1d252d;
  margin-bottom: 8px;
}

.container-static-personal  {
  width: 100%;
  max-width: 552px;
  display: flex; 
  flex-wrap: wrap;
  gap:  16px;
}

.div-razon-social {
  width: 100%;
  margin-top: 4px;
  display: flex;
  max-width: 520px;

  span {
    margin-left: 0.75rem;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #444444;
    font-family: Str-Microtext-Regular , sans-serif;
  }
}