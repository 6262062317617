@import "../../../breakpoints_new";

.card-redirect {
  border: 1px solid #CEDEE7;
  border-radius: 12px;
  overflow: hidden; 
  width: 100%;
  box-shadow: 0px 1px 6px 0px #44444433;
  max-width: 370px;
  height: 100%;

  @media (min-width: 768px) {
    width: calc((100% - 48px) / 3);

  }

  &__hero {
      position: relative;
      aspect-ratio: 3/2; 
      overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      
     }
  }

  &__content {
    padding: 16px;
    position: relative;
    

    @media (min-width: 320px) {
      height: auto;
		}

    @media (min-width: 768px) {
			height: 212px;
		}

    @media (min-width: 1200px) {

			height: 152px;
		}


    &__title {
      color: #222222;
      font-family: 'Str-Headline-Bold', sans-serif;
      font-size: 20px;
    }

    &__description {
      font-family: 'Str-Microtext-Regular', sans-serif;
      color: #727272;
      font-size: 16px;
    }

    &__button {
      
      position: relative;
      bottom: 0px;
      margin-top: 8px;

      @media (min-width: 768px) {
        position: absolute;
        bottom: 16px;
      }

      div {
        font-family: Str-Microtext-Bold, sans-serif;
        color: #EC0000;
        font-size: 14px;

        @media (min-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
}

button {
  background-color: transparent;
  padding: 0px;
  text-align: left;
  border: none;
  color: none;
}

.text-strong {
  font-weight: bold;
  color: #000000;
}

