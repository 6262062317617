.container__FormularioSimulacion{
    width: 680px;
    margin: 0 auto;
    .formulario__FormularioSimulacion{
        display: grid;
        height: 200px;
        grid-template: repeat(2, 1fr) / repeat(2, 1fr);
        grid-gap: 16px 24px;
    }
    .formulario__FormularioSimulacionIndividual{
        display: grid;
        height: 230px;
        grid-template: repeat(2, 1fr) / repeat(2, 1fr);
        grid-gap: 23px 28px;
    }
}

@media only screen and (max-width: 700px) {
    .container__FormularioSimulacion{
        width: 90%;
        margin: 0 auto;
        .formulario__FormularioSimulacion{
            display: block;
            height: 300px;
        }
        .formulario__FormularioSimulacionIndividual{
            display: block;
            height: 500px;
        }
    }
}
