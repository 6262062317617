.container-form-information {
  width: 100%;
  padding: 0 0.25rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 24px;
  .css-16awh2u-MuiAutocomplete-root {
    width: 100%;
    margin: 0 !important;
  }
  .css-1d1r5q-MuiFormHelperText-root.Mui-error{
    margin-top: -12px !important;
  }
}

.container-static-information {
  width: 100%;
  max-width: 552px;
  display: flex; 
  flex-wrap: wrap;
  gap: 16px;

  .title {
    font-family: "Str-Headline-Bold", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #1d252d;
    margin-bottom: 8px;
  }
}

.container-radio-button-information {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  #titleRadio {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: "Str-Microtext-Regular", sans-serif !important;
    color: #444444;
  }
}

.container-date-information {
  width: 100%;
  input {
    ::-webkit-calendar-picker-indicator {
      filter: invert(17%) sepia(70%) saturate(5230%) hue-rotate(356deg)
        brightness(84%) contrast(126%);
    }
  }
}

.col-information {
  width: 100%;
  display: block;

  @media (min-width: 768px) {
    gap: 24px;

    #dropdown {
      max-width: 264px;
    }
    #dropdown {
      max-width: 264px;
    }
  }
}

.text-optional-information {
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #444444;
  margin-top: -12px;
  display: flex;
  font-family: Str-Microtext-Regular, sans-serif;

  span {
    width: 100%;
    max-width: 520px;
    margin-left: 0.75rem
  }
}