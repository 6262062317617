.section-operation {
	background-color: #F6FAFC;
	color: #222222;
	padding: 48px 0px;

	@media (min-width: 768px) {
		padding: 64px 0px;
	}

	&--hero {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 12px;
		width: auto;
		height: 222px;

		@media (min-width: 768px) {
			height: 628px;
			flex: 1;
		}

		@media (min-width: 1200px) {
			width: 425px;
			height: 392px;
			flex: none;
		}
	}

	&--steppers {
		flex: 1;

		ol {
			padding-left: 0px;
			margin-bottom: 0px;
		}

		&__item {
			position: relative;
			display: flex;
			gap: 1rem;


			&:before {
				content: attr(data-content);
				flex: 0 0 var(--circle-size);
				height: var(--circle-size);
				border-radius: 50%;
				border: 1px solid #EC0000;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: #727272;
				font-family: "Str-Microtext-Bold", sans-serif;
			}

			&:not(:last-child) {
				&:after {
					content: "";
					position: absolute;
					left: 0;
					top: calc(var(--circle-size) + var(--spacing));
					bottom: var(--spacing);
					transform: translateX(calc(var(--circle-size) / 2));
					width: 1px;
					background-color: #CCCCCC;
				}

				padding-bottom: 32px;

			}

		}

		&__content {


			&__title {
				font-family: "Str-Headline-Bold", sans-serif;

			}

			&__description {
				font-family: "Str-Microtext-Regular", sans-serif;
				line-height: 24px;
				margin-bottom: 0px;
				font-size: 14px;
				color: #727272;
			}
		}
	}


}

.container-section-operation {
	display: flex;
	gap: 48px;
	flex-direction: column-reverse;
	margin: auto;
	margin-top: 48px;

	@media (min-width: 768px) {
		flex-direction: row;
	}




}