@import "../../../../../breakpoints_home";

.slider-custom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  align-items: center;
  
  .container-text {
    height: 100%;
    display: flex;
    
    @media #{$mq-mobile} {
      justify-content: center;
    }
  }

  @media #{$mq-mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  &__principal {
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 12px;
    overflow: hidden;

  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 48px;

    @media #{$mq-tablet} {
      padding: 0px 24px;
    }

    @media #{$mq-mobile} {
      justify-content: normal;
      padding: 24px 16px;
      width: 100%;
      max-width: 480px;
      box-sizing: content-box;
    }

    &__title {
      font-family: Str-Headline-Bold, sans-serif;
      line-height: 64px;
      font-size: 54px;

      @media #{$mq-tablet} {
        font-size: 42px;
        line-height: 52px;
      }

      @media #{$mq-mobile} {
        font-size: 28px;
        line-height: 32px;
      }
      
    }

    &__description {
      font-family: Str-Microtext-Regular, sans-serif;
      font-size: 18px;
      margin-top: 24px;
      line-height: 24px;

      @media #{$mq-mobile} {
        margin-top: 16px;
      }

    }

    &__subtitle {
      font-family: Str-Microtext-Bold, sans-serif;
      margin-top: 24px;
      font-size: 18px;
      @media #{$mq-mobile} {
        margin-top: 16px;
      }
    }
  }

  .span-regular {
    font-family: Str-Headline-Regular, sans-serif;
  }

}

.buttons-sliders {
  display: flex; 
  gap: 24px;
  margin-top: 32px;

  @media (max-width: 768px) {
    display: flex; 
    flex-direction: column;
    gap: 12px;
  }
}
