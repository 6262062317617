.container-check-options {
  display: flex;
flex-wrap: wrap;
align-items: center;
width: 100%;
gap: 4px;

span{
 padding: 0;
}

div{
 font-family: "Str-Microtext-Regular", sans-serif;
}

.text-check{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
 
}

.text-check-warning{
  width: 100%;
  margin-left: 2.5rem;
  color : #CC0000;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

}

.col-content-check{ 
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 16px;
}
}