.list-places-width{
    width: 321px;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
    .list-places-width{
        width: 100%;
    }
}
@media screen and (min-width: 576px) and (max-width: 1199px) {
    .list-places-width{
        width: 100%;
    }
}