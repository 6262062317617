.response-form {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__body {
    text-align: center;

    h4 {
      color: #444444;
      font-family: Str-Headline-Bold, sans-serif;
      margin-bottom: 12px;
      font-size: 24px;
    }

    span {
      color: #222222;
      font-family: Str-Microtext-Regular, sans-serif;
      font-size: 18px;
    }
  }
}