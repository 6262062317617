@import "../../../breakpoints";

.slide-santander {
  background-color: #EC0000;
  padding: 44px 174px;
  

  @media #{$mq-tablet3} {
    padding: 64px 48px;
  }

  @media #{$mq-tablet2} {
    padding: 64px 48px;
  }

  @media #{$mq-tablet1} {
    padding: 64px 48px;
  }

  @media #{$mq-mobile} {
    padding: 48px 16px;
  }

  &__content {
    display: flex;
    color: #FFFFFF;
    font-family: 'Str-Microtext-Regular', sans-serif;
    font-size: 16px;

    @media #{$mq-tablet2} {
      display: block;
      text-align: center;
    }

    @media #{$mq-tablet1} {
      display: block;
      text-align: center;
    }

    @media #{$mq-mobile} {
      display: block;
      text-align: center;
    }
    

    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      @media #{$mq-tablet2} {
        margin-bottom: 40px;
      }

      @media #{$mq-tablet1} {
        margin-bottom: 40px;
      }

      @media #{$mq-mobile} {
        margin-bottom: 40px;
      }

      span {
        @media #{$mq-mobile} {
          font-size: 16px;
        }
      }

    }

    &__separador-vertical {
      height: 130px;
      width: 1px;
      background: #ECECEC;
      margin: auto 20px;

      @media #{$mq-tablet2} {
        display: none;
      }

      @media #{$mq-tablet1} {
        display: none;
      }

      @media #{$mq-mobile} {
        display: none;
      }
    }

    &__description {
      p {
        margin: 0px;

        @media #{$mq-mobile} {
          font-size: 16px;
        }
      }

      
      span {
        text-decoration: underline;
      }
    }
  }

}