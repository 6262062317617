@import "../../../breakpoints_new";


.custom-tabs-home {
  .MuiButtonBase-root {
    @media #{$mq-small} {
      flex: 1;
    }
  }
}

.box-content-tab-home {

  @media #{$mq-medium} {
    padding: 64px 24px !important;
  }

  @media #{$mq-small} {
    padding: 24px 16px 64px 16px !important;
  }
}

.content-tab-home {
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}

.content-tab-home.fade-in {
  opacity: 1;
}
