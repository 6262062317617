.list-tab-item-ubicacion {
  margin-bottom: 15px;

  font-family: "Str-Microtext-Regular", sans-serif;
  list-style-image: url("../../assets/images/itemblanco.svg");
  list-style-position: outside;
  align-self: center;
  color: white;
  font-size: 16px;
}

.banner-prospera {
  background-image: url('../../assets/images/Producto_Desktop_Prospera_@2x.jpg');
  background-color: #eff8fc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 440px;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 150;
}

.title-banner {
  display: block;
  font-family: Str-Headline-Bold, sans-serif;
  font-size: 52px;
  margin-top: 12% !important;
  text-align: left;
  line-height: 1.5;
  margin-left: 5%;
  margin-right: 50%;
}

.subTextBannerP {
  display: block;
  color: #444444;
  font-size: 28px;
  font-family: "Str-Microtext-Regular", sans-serif;
  text-align: left;
  line-height: 1.5;
}

.banner-construyendo {
  background-image: url('../../assets/images/Banner_CS_Informativo desktop CONSTRUYENDO SUEÑOS AL 23 NOVIEMBRE.png');
  background-color: #f5f9fb;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  border-radius: 12px;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 150;
  alt: "Construyendo Sueños SURGIR Banner Producto"

}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .banner-prospera {
    background-image: url('../../assets/images/Producto_Mobile_Prospera_@4x.jpg');
    background-color: #f5f9fb;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    height: 300px;
    width: 100%;
    position: relative;
    display: flex;
    margin-top: 150;
    alt: "Crédito Prospera SURGIR Banner Producto"
  }

  .title-banner {
    display: block;
    color: #222222 !important;
    font-size: 28px !important;
    font-family: "Str-Headline-Bold", sans-serif;
    margin-top: 46%;
    margin-left: 5%;
    text-align: left;
    line-height: 32px !important
  }

  .subTextBannerP {
    display: block;
    color: #444444;
    font-size: 16px;
    font-family: "Str-Microtext-Regular", sans-serif;
    text-align: left;
    margin-top: 12px !important;
    line-height: 1.5;
  }

  .banner-construyendo {
    background-image: url('../../assets/images/Banner_CS_Producto Mobile AL 23 NOVIEMBRE.png');
    background-color: #f5f9fb;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
    border-radius: 12px;
    width: 100%;
    position: relative;
    display: flex;
    margin-top: 150;
    alt: "Construyendo Sueños SURGIR Banner Producto"
  }


}

@media screen and (min-width: 576px) and (max-width: 1000px) {
  .banner-prospera {
    background-image: url('../../assets/images/Producto_Tablet_Prospera_@2x.jpg');
    background-color: #f5f9fb;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    height: 390px;
    width: 100%;
    position: relative;
    display: flex;
    margin-top: 150;
    alt: "Crédito Prospera SURGIR Banner Producto"
  }

  .banner-construyendo {
    background-image: url('../../assets/images/Banner_CS_Informativo tablet CONSTRUYENDO SUEÑOS AL 23 NOVIEMBRE.png');
    background-color: #f5f9fb;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    border-radius: 12px;
    width: 100%;
    position: relative;
    display: flex;
    margin-top: 150;
    alt: "Construyendo Sueños SURGIR Banner Producto"
  }

  .title-banner {
    display: block;
    color: #222222;
    font-size: 24px !important;
    font-family: "Str-Headline-Bold", sans-serif;
    margin-top: 1%;
    margin-left: 5%;
    text-align: left;
    line-height: 1.5
  }

  .subTextBannerP {
    display: block;
    color: #444444;
    font-size: 20px;
    font-family: "Str-Microtext-Regular", sans-serif;
    text-align: left;
    margin-top: 10px;
    line-height: 1.5;
  }
}

.body-segment-content-solidario {
  .accordion .card:first-of-type {
    border-bottom: 1px solid rgba(0, 0, 0, 0.175) !important;
  }
  .nav-tabs{
    .nav-item {
      flex-grow: initial;
    }
  }

  @media (min-width: 768px) {
    .Columna {
      font-size: 18px;
    }
    .Descarga {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 992px) {
    .Descarga {
      gap: 5px;
    }
  }

  @media (min-width: 1024px) {
    .Columna {
      font-size: 28px;
    }
  }

  @media (min-width: 1200px) {
    .nav-tabs{
      .nav-item {
        flex-grow: 1;
      }
    }
  }
}

.building-dreams-content {
  padding: 40px;
  @media (min-width: 768px) {
    .card-body .linkrojo{
     font-size: 15px;
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     align-items: center;
    }
  }
  @media (min-width: 992px) {
    .card-body .linkrojo{
     font-size: 16px;
     gap: 5px;
    }
  }
}

.row-also-be-interested{
  padding: 3% 1% 3% 3%;
  margin: auto;

  @media (min-width: 576px) {
    .card-body .linkrojo {
      margin-left: auto;
    }

    .interest-col {
       width: 196px;
    }

    .learn-more {
      margin-left: 10px;
    }
  }

  @media (min-width: 992px) {
    .interest-col {
      width: 25%;
   }
  }

  @media (min-width: 1200px) {
    .card-body .linkrojo {
      margin-left: 70%;
    }
  }
}

.working-capital-card {
   @media (min-width: 576px) {
    .parrafo {
      margin-bottom: 13px;
      height: 120px;
     }
   }

   @media (min-width: 768px) {
    .parrafo {
      height: 72px;
     }
   }

   @media (min-width: 1200px) {
    .parrafo {
      height: 48px;
     }
   }
}

.col-easy-steps {
  padding-top: 10px;

  @media (min-width: 576px) {
     img {
      position: relative;
      left: -15px;
     }
  }

  @media (min-width: 768px) {
    img {
      position: relative;
      left: auto;
     }
  }

}

.body-segment-content-solidario-p {
  br {
    line-height: 0;
  }
}

.boxi {
  background-color: #EFF6F8;
  padding-top: 40px;
  padding-bottom: 80px;
}

.box {
  background-color: #257fa4;
  width: 100 vh;
}

.cardsprospera {
  flex: 0 0 auto !important;
  border: 10px solid;
  border-color: #ffffff;
  margin: 20px;
  border-radius: 18px;
  padding: 0 5% 5% 5%;
  background-color: #EFF6F8;

}

.segment-containerp {
  padding-bottom: 70px;
  border-bottom-width: 2px;
  border-bottom-color: #ccc;

}

.iframe {
  width: 100%;
  border-radius: 30px;
}

.margen {
  line-height: 1 !important;
}

.card-style {
  border-radius: 16px;
}



.image1 {
  width: 100%;
}

.image2 {
  display: none;
}

.image3 {
  display: none;
}

@media only screen and (max-width: 574px) {

  .image1 {
    display: none;
  }

  .image2 {
    display: block;
  }

  .image3 {
    display: none;
  }

}

@media screen and (min-width: 574px) and (max-width: 1199px) {
  .image1 {
    display: none;
  }

  .image2 {
    display: none;
  }

  .image3 {
    display: block;
  }

}