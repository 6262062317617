@import "~bootstrap/scss/bootstrap";
@import "./assets/css/surgir.scss";

body {
  margin: 0;
  font-family: "Str-Microtext-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.btn {
  &:focus {
    outline: none !important; // Elimina el contorno predeterminado
    box-shadow: none !important; // Elimina la sombra predeterminada al enfocar
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

li {
  list-style-type: none;
}

br {
  line-height: 0;
}

a {
  color: #257FA4;
}


a:focus {
  color: #0c6a8f;
}

a:hover {
  color: #0d4d66;
}


.center-div {
  margin: 0px auto;
  justify-content: center;
  align-items: center;
}


.parrafo{
  font-size: 16px;
  font-family: "Str-Microtext-Regular", sans-serif;
  color: #444444
}


.Columna {
  font-size: 28px;
  font-family: "Str-Headline-Regular", sans-serif;
  color: #1d252d !important
}

.Links {

  color: '#257fa4';
  text-decoration-style: none !important
}

.parrafow {
  font-size: 16px;
  font-family: "Str-Microtext-Regular", sans-serif;
  color: white;
}

.parrafo-bold {
  font-size: 16px;
  font-family: "Str-Headline-Bold", sans-serif;
  color: #444444;
}

.subtitulos{
  font-family:  'Str-Headline-Bold', sans-serif;
  color: #444444 ;
  font-size: 17px;
  
}

.Descarga {
  font-size: 16px;
  font-family: "Str-Microtext-Regular", sans-serif;
  color: #444444;
  text-decoration: none;
}

.linkrojo {
  color: #ec0000;
  text-decoration: none;
  font-family: "Str-Microtext-Regular", sans-serif;
}

.linkrojo:hover {
  color: #990000 !important;
  text-decoration: none;
}

.linkturquesa:hover {
  color: #1c5f7b !important;
}

.linkturquesa:active {
  color: #134052 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.subtitle_form {
  font-size: 18px !important;
  font-family: "Str-Headline-Bold", sans-serif;
  color: #1d252d !important;
  margin-bottom: 20px !important;
}

.subtitle_form2 {
  font-size: 16px !important;
  font-family: "Str-Headline-Bold", sans-serif;
  color: #444444 !important;
  margin-bottom: 20px !important;
}



.parrafo-gris {
  font-size: 18px !important;
  font-family: "Str-Headline-Bold", sans-serif;
  color: #444444 !important;
  margin-bottom: 20px !important;
}

.position-item-card {
  font-size: 120pt;
  font-family: Str-Headline-Bold, sans-serif;
  color: $primary-sky-50-eff-6-f-9;
  display: block;
  line-height: 0.8;
  align-items: center;
}

.title-item-card {
  font-size: 14pt;
  font-family: Str-Headline-Bold, sans-serif;
  color: $primary-athracite-1-d-252-d;
  display: block;
  text-align: center;
}






@media (max-width: 600px) {
  .subtitle_form {
    font-size: 16px;
    font-family: "Str-Headline-Bold", sans-serif;
    color: #1d252d !important;
    margin-bottom: 20px !important;
  }
}

.title_modal {
  font-size: 20px;
  font-family: "Str-Headline-Bold", sans-serif;
  color: #1d252d !important;
}

@media (max-width: 600px) {
  .title_modal {
    font-size: 16px;
    font-family: "Str-Headline-Bold", sans-serif;
    color: #1d252d !important;
  }
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #ec0000 !important;
}

.radio-label-mo {
  .css-ahj2mt-MuiTypography-root {
    font-weight: 700;
    font-family: "Str-Headline-Bold", sans-serif;
    color: #1d252d;
  }
}

.text-date-surgir {
  input {
    font-family: "Str-Microtext-Regular", sans-serif;
    color: #444444;
  }
}

@media (min-width: 600px) {
  .snack-mod {
    left: 50% !important;
  }
}

.imagedestokp {
  width: 100%;
}

.imagemobile {
  display: none;
}

.imagetablet {
  display: none;
}

@media only screen and (max-width: 575px) {
  
  .imagedestokp {
    display: none;
  }

  .imagemobile {
    display: block;
  }

  .imagetablet {
    display: none;
  }
}

@media screen and (min-width: 576px) and (max-width: 900px) {
  .imagedestokp {
    display: none;
  }

  .imagemobile {
    display: none;
  }

  .imagetablet {
    display: block;
  }
}

.title-section {
  font-family: "Str-Headline-Bold", sans-serif;
  font-size: 28px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 32px;
  }
}



