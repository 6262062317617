.footer-bottom-container-libro {
    text-align: center;
    > span,
    a {
        color: white;
    }
    .fotter-botton-container-libro__icon {
        width: 60px;
    }
    .parrafow {
        font-family: "Str-Headline-Bold";
    }
}
@media only screen and (max-width: 580px) {
    .footer-bottom-container-libro {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .fotter-botton-container-libro__icon {
            width: 24px;
        }
    }
}
@media screen and (min-width: 575px) and (max-width: 1199px) {
    .footer-bottom-container-libro {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .fotter-botton-container-libro__icon {
            width: 24px;
        }
    }
}

@media (min-width: 768px) {
    .footer-bottom-container-libro {
        .parrafow {
            font-size: 14px;
        }
        .fotter-botton-container-libro__icon {
            width: 24px;
        }
    }
}

@media (min-width: 1200px) {
    .footer-bottom-container-libro {
        display: flex;
        gap: 10px;
    }
}