.textOptionalFile {
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: #767676;
  position: absolute;
  bottom: -4px;
  text-align: center;
  left: -1.125rem;
  font-family: Str-Microtext-Regular, sans-serif
}
.containet-icon{
  margin-top: 1rem;
  cursor: pointer;
  margin-right: 0.063rem;
  width: auto;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 768px) {
    margin-right: 0.5rem;
  }
}