@import "../../../breakpoints_new";

.slider-container {
  &__body {
    &__content {
      
      margin: 24px;
      height: 500px;
      margin-top: 0px;

      @media #{$mq-medium} {
        height: 440px;
      }

      @media #{$mq-small} {
        margin: 16px;
        margin-bottom: 12px;
        margin-bottom: 24px;
      }

    }
  }

  ul .slick-active div {
    position: relative;
    width: 29px !important;
    height: 12px;
    border-radius: 100px !important;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #EC0000;
      border-radius: 100px; 
      animation: slideLoading 8s linear forwards;
    }
  }

  ul {
    display: flex;
    gap: 7px;
    align-items: center;
  }

  .slick-arrow {
    display: none !important;
  }

}

@keyframes slideLoading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.button-sliders {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  box-shadow: 0px 1px 6px 0px rgba(68, 68, 68, 0.2);
  background-color: #FFFFFF;
  justify-content: center;
  align-items: center;
  
  img {
    width: 24px;
    height: 24px;
    
  }
}