.nav-tabs{
  margin-top: 30px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  color:     #1d252d;
  text-decoration-line: underline;

  text-underline-offset: 8px;
  text-decoration-thickness: 4px;
  font-family: Str-Microtext-Regular , sans-serif;
  border-color: #ffffff #ffffff #ffffff !important;
  background-color: transparent !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
  color :#767676;
  font-family: Str-Microtext-Regular, sans-serif;
}


.parrafo-bold-decoration{
  font-size: 16px;
  font-family: "Str-Headline-Bold", sans-serif;
  color:#444444;
  font-style: italic;
  text-decoration: underline;
  }   

.procediments {
  ol li {
      list-style-type: auto;
      ul li {
        list-style-type: initial;
      }
  }
}