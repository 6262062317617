@import "../../assets/fonts/typography/typography";
@import "../../breakpoints_home";

.sliders-home {
  @media #{$mq-desktop-fhd} {

    width: 100%;
    max-width: 1440px;
    margin: auto;
  }
}

.container-std {
  padding: 24px;

  @media #{$mq-mobile} {
    padding: 16px;
  }

  @media #{$mq-desktop-fhd} {
    width: 100%;
    max-width: 1440px;
    margin: auto;
  }

}

.home-cards {
  padding: 48px 36px;
  background-color: #F5F9FB;
  border-radius: 12px;
  margin-bottom: 8px;
  
  @media #{$mq-tablet} {
    margin-bottom: 0px;
  }

  @media #{$mq-mobile} {
    padding: 48px 16px;
    margin-bottom: 0px;
    margin-top: 8px;
  }
  

  &__box {
    display: flex;
    gap: 24px;
    margin-top: 40px;
    justify-content: center;

    @media #{$mq-tablet} {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;
    }

    @media #{$mq-mobile} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      margin: auto;
      max-width: 480px;
      margin-top: 40px;
    }

  }
}

.home-products {
  padding-top: 64px;

  @media #{$mq-tablet} {
    padding-top: 40px;
  }

  @media #{$mq-mobile} {
    padding-top: 40px;
  }

}

.home-about {
  padding: 96px 0px;
  padding-top: 72px;

  @media #{$mq-tablet} {
    padding: 64px 0px;
    padding-top: 40px;
  }

  @media #{$mq-mobile} {
    padding: 64px 0px;
    padding-top: 48px;
    max-width: 480px;
    margin: auto;
  }

  &__title-responsive {
    display: none;
    // margin: auto;
   
    
    @media #{$mq-tablet} {
      display: block;
      margin-bottom: 64px;
 
    }

    @media #{$mq-mobile} {
      display: block;
      margin-bottom: 40px;
     
    }

  }

  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    
    @media #{$mq-mobile} {
      display: flex;
      gap: 24px;
      flex-direction: column-reverse;
    }

    &__hero {
      margin: auto;
  
      img {
        width: 100%;
      }
    }
    
    &__content {
      width: 100%;
      max-width: 567px;

      &__title {
        margin-bottom: 40px;
        
        @media #{$mq-tablet} {
          display: none;
        }

        @media #{$mq-mobile} {
          display: none;
        }
      }
  
      &__list {
        ul li {
          display: flex;
          gap: 16px;
        }
        
        &__icon {
          img {
            @media #{$mq-mobile} {
              width: 40px;
            }
          }
        }
        &__description {
          h5 {
            font-family: 'Str-Headline-Bold', sans-serif;
            color: #222222;
            
            @media #{$mq-mobile} {
              font-size: 18px;
            }
          }
  
          p {
            font-family: 'Str-Microtext-Regular', sans-serif;
            color: #727272;
            
          }
        }
      }
    }
  }

  .button-about {
    @media #{$mq-mobile} {
      display: none;
    }
  }

  .button-about-responsive {
    display: none;

    @media #{$mq-mobile} {
      display: block;
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }
  }
}



.home-videos-body {
  padding: 96px 0px;

  @media #{$mq-tablet} {
    padding: 64px 0px;
  }

  @media #{$mq-mobile} {
    padding: 64px 0px;
    max-width: 480px;
    margin: auto;
  }
}

.home-videos {
  display: flex;
  gap: 40px;

  @media #{$mq-tablet} {
    display: block;
  }

  @media #{$mq-mobile} {
    display: block;
  }

  &__content {
    flex: 1;
    padding: 38px 24px;

    &__title {
      max-width: 482px;
      margin-bottom: 40px;


      @media #{$mq-tablet} {
        max-width: 100%;
        margin: auto;
      }

      @media #{$mq-mobile} {
        max-width: 100%;
        margin: auto;
        margin-bottom: 40px;
      }

    }

    @media #{$mq-desktop} {
      padding: 0px;
    }

    @media #{$mq-tablet} {
      padding: 0px;
      margin-bottom: 40px;
    }

    @media #{$mq-mobile} {
      padding: 0px;
    }


    &__card {
      border: 1px solid #CEDEE7;
      background-color: #F5F9FB;
      border-radius: 12px;
      padding: 16px;
      display: flex;
      gap: 10px;
      align-items: center;

      &__icon {
        width: 64px;
        height: 64px;
        border-radius: 100%;
        background-color: #FFFFFF;
        flex-shrink: 0;
        display: flex; 
        justify-content: center; 
        align-items: center;

      }

      &__text {
        flex: 1;
        color: #222222;
        font-family: 'Str-Headline-Bold', sans-serif;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  &__player {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 16px;
    width: 760px;
    height: 100%;

    @media #{$mq-tablet} {
      width: 100%;
      
    }

    @media #{$mq-mobile} {
      width: 100%;
      grid-template-columns: 1fr;
      padding: 0px;
    }

    &__principal {
      position: relative;
      aspect-ratio: 3/2; 
      overflow: hidden;
    }

    &__secondary {
      display: grid;
      grid-template-rows: repeat(3, auto);
      gap: 16px;

      @media #{$mq-mobile} {
        grid-template-columns: repeat(3, auto);
        grid-template-rows: none;
        justify-content: center;
      }

     

      &__card {
        border-radius: 12px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        
        @media #{$mq-mobile} {
          height: 56px;
          width: 86px;
        }
        

        &__content {
          box-shadow: 0px 1px 6px 0px #44444433;
          width: 100%;
          height: 100%;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

  }
}

.card-youtube-desktop {
  @media #{$mq-tablet} {
    display: none;
  }

  @media #{$mq-mobile} {
    display: none;
  }
}
.card-youtube-reponsive {
  display: none;
  max-width: 440px;
  padding-top: 40px;
  margin: auto;

  @media #{$mq-tablet} {
    display: block;
  }

  @media #{$mq-mobile} {
    display: block;
  }
}

.grid-container-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  
  @media (min-width: 768px) {
    flex-direction: initial;
    gap: 24px;
  }
}

