.banner-quienes-somos{
  background-image: url('../../assets/images/banner-quienessomos.png');
  background-color: #f5f9fb;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 502px;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 100 ;
 
}
@media (max-width: 600px){
  .banner-quienes-somos{
    background-image: url('../../assets/images/banner-quienessomos.png');
    background-color: #f5f9fb;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 339px;
    width: 100%;
    position: relative;
    display: flex;
    margin-top: 100 ;
   
  }
}

.text-banner-quienes-somos{
  display: block;
  text-align: left; 
  margin-top: 281px;
  margin-left: 60px;
  color: white;
  font-size: 48px; 
  font-family: "Str-Headline-Bold", sans-serif;
}

@media (max-width: 600px){
  .text-banner-quienes-somos{
    display: block;
    text-align: left; 
    margin-top: 195px;
    margin-bottom: 64px;
    margin-left: 16px;
    margin-right: 16px;
    color: white;
    font-size: 32px; 
    font-family: "Str-Headline-Bold", sans-serif;
  }
}

.subtitle_culture{
    font-size: 18px;
    font-family: "Str-Headline-Bold", sans-serif;
    color:#1d252d !important;
    margin-bottom: 20px !important;
  }

.text-rectangle {
  font-size: 18px;
  font-family: "Str-Headline-Bold", sans-serif;
  padding-left: 56px;
}

.subtext-rectangle {
  font-family: "Str-Microtext-Regular", sans-serif;
  font-size: 14px;
}

.banner-medio {
  background-color: #EC0000;
  width:100 vh;
  height: 369px;
}

.box_text_banner{
  padding-top: 110px;
  padding-left: 190px;
  padding-right: 190px;
}

.text_banner_medio
{
  font-size: 48px;
  text-align: left;
  color: white; 
  font-family: "Str-Headline-Regular", sans-serif;
}

@media (max-width: 600px){
  .banner-medio {
    background-color: #EC0000;
    width:100 vh;
    height: 282px;
  }

  .box_text_banner{
    padding-top: 48px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 48px;
  }

  .text_banner_medio{
    font-size: 32px;
    text-align: left;
    color: white; 
    font-family: "Str-Headline-Regular", sans-serif;
  }
}
.Ptitle{
  font-size: 24pt;
  font-family: "Str-Headline-Bold" , sans-serif ;
  color: #9E3667;
  text-align: left;
  display: block;
  margin-top: 120px;
  padding-bottom: 32px;
}
.logros{
  font-family: "Str-Headline-Bold", sans-serif;
  font-size: 24px;
  text-align: left;
}

@media (max-width: 600px){
  .Ptitle{
    font-size: 24pt;
    font-family: "Str-Headline-Bold" , sans-serif ;
    color: #9E3667;
    text-align: left;
    display: block;
    margin-top: 48px;
    padding-bottom: 24px;
  }
  .logros{
    font-family: "Str-Headline-Bold", sans-serif;
    font-size: 18px;
    text-align: left;
  }
}

.valores{
  font-family: "Str-Headline-Regular", sans-serif;
  font-size: 48px;
  text-align: left;
}


  