@import "../../../breakpoints_home";

.top-banner {
  width: 100%;
  background-color: #EC0000;
  position: fixed;
  top: 0;
  opacity: 1;
  transition: opacity 0.5s ease;
  padding: 8px 0px;

  &__container {
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px;

    @media #{$mq-mobile} {
      padding: 0px 16px;
    }

    @media #{$mq-desktop-fhd} {
      width: 100%;
      max-width: 1440px;
      margin: auto;
    }

    &__logo {
      span {
        font-size: 16px;
        font-family: 'Str-Microtext-Regular', sans-serif;

        @media #{$mq-mobile} {
          font-size: 12px;
        }
      }

      img {
        margin-left: 8px;
        @media #{$mq-mobile} {
          width: 73px;
        }
      }
    }

    a {
      padding: 0 !important;
      color: #FFFFFF !important;
      font-family: 'Str-Microtext-Bold', sans-serif !important;
      font-size: 14px !important;
    }
  }
}


.transition-top-banner {
  height: 48px;
  transition: height 0.5s ease;
  top: 0;
  opacity: 0;
}