@import "../../../breakpoints_home";

.slide-secondary-container {
  padding: 24px;
  background-color: #DEEDF2;
  border-radius: 12px;
}

.slide-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

  @media #{$mq-desktop} {
    padding: 24px 50px;
  }

  @media #{$mq-tablet} {
    padding: 24px;
    gap: 40px;
    
  }

  @media #{$mq-mobile} {
    flex-direction: column;
    padding: 48px 24px;
    gap: 40px;
    max-width: 480px;
    margin: auto;
  }

  &__content {
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 32px;
    align-items: center;
    width: 100%;
    max-width: 861px;

    @media #{$mq-tablet} {
      grid-template-columns: 1fr;
      gap: 24px;
    }

    @media #{$mq-mobile} {
      grid-template-columns: 1fr;
      text-align: center;
      gap: 40px;
    }

    &__title {
      margin-bottom: 0px;
      font-family: 'Str-Headline-Bold', sans-serif;
      font-size: 28px;
      line-height: 32px;

      @media #{$mq-mobile} {
        font-size: 24px;
      }
    }

  }



}