.container-result-search{
    padding: 52px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .container-result-search-fixed {
        width: 100%;
        padding: 0 16px;
        background-color: #F5F9FB;
        display: flex;
        justify-content: center;

        .container-search-static{
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .spacing-desktop{
            display: none;
        }
        .container-fixed-center{
            width: 100%;
            max-width: 480px;
        }
        .detail-search{
            padding: 40px 0;
            span {
                display: block;
               &:first-child{
                color: #727272;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                font-family: "Str-Microtext-Regular", sans-serif;
                margin-bottom: 10px;
               }
               &:last-child{
                color: #222222;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                font-family: "Str-Headline-Bold", sans-serif;
               }
            }
        }

        .input-search{
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            padding: 12px;
            border: 1px solid #8F8F8F;
            box-shadow: 0px 0px 0px 0px #44444400;
            border-radius: 8px;
            background-color: #ffffff;
            flex: 1;
            input {
                border: none;
                outline: none;
                flex: 1;
            }
            .ic-search {
                content: url('../../assets/icons/search-icon.svg');
            }
            .ic-clear {
                cursor: pointer;
                content: url('../../assets/icons/clear-icon-circle-blue.svg');
            }
        }
    }

    .container-fixed-results{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .container-center-result{
        display: flex;
        justify-content: space-between;
    }

    .container-results{
        overflow: auto;
        background-color: #ffffff;
        width: 100%;
        max-width: 444px;
        border-radius: 8px;

        .text-head{
            margin-top: 16px;
            font-size: 12px;
            display: flex;
            gap: 5px;
            padding: 0 16px;
            font-family: "Str-Microtext-Regular", sans-serif;
            p {
                margin: 0;
                &:first-child{
                  font-weight: 400;
                  line-height: 16px;
                  color: #727272;
                }

                &:last-child {
                    font-weight: 700;
                    line-height: 16px;
                    color: #727272;
                }
            }
        }

        .item-option{
            display: flex;
            cursor: pointer;
            gap: 8px;
            padding: 16px;
            border-bottom: 1px solid #CEDEE7;
            &:last-child {
                border-bottom: 0;
            }
            justify-content: space-between;
            .ic-arrow-right{
                content: url('../../assets/icons/arrow-right-gray.svg');
            }
            .ic-download {
                content: url('../../assets/icons/icon-download.svg');
            }
            .detail-option{
                display: flex;
                gap: 8px;
                div{
                    display: flex;
                    flex-wrap: wrap;
                }
                .tag-result{
                    padding: 0 8px;
                    color: #23779A;
                    box-shadow: 0px 0px 0px 0px #44444400;
                    background-color: #DEEDF2;
                    font-size: 14px;
                    line-height: 20px;
                    font-family: "Str-Microtext-Regular", sans-serif;
                    font-weight: 700;
                    margin-right: 4px;
                    border-radius: 4px;
                }
                span {
                    width: 100%;
                    color: #222222;
                    &:first-child{
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        font-family: "Str-Headline-Bold", sans-serif;
                        display: flex;
                        align-items: center;
                        p{
                            margin: 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 1;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            white-space: normal;
                        }
                    }
                    &:last-child{
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        font-family: "Str-Microtext-Regular", sans-serif;
                    }
                }
                
            }
        }
    }

    .filters-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        cursor: pointer;
       img{
        content: url('../../assets/icons//chevron-down-small.svg');
        transition: 0.5s ease-out;
       }
       .rotate-180 {
        transform: rotate(180deg);
       }
       div{
        display: flex;
        gap: 5px;
        align-items: center;
        span {
            color: #222222;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            font-family: "Str-Headline-Bold", sans-serif;
            cursor: auto;
        }
       }
       span{
            color: #2222223B;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            font-family: "Str-Headline-Bold", sans-serif;
            margin-right: 20px;
            cursor: pointer;
       }
       .active-clear {
         color: #4C98B3;
       }
    }

    .container-filters-chips {
        display: flex;
        gap: 8px;
        padding: 0 8px 32px;
        flex-wrap: wrap;
       .chip-filter{
        width: max-content;
        background-color: #ffffff;
        box-shadow: 0px 1px 6px 0px #44444433;
        border-radius: 32px;
        padding: 6px 8px;
        color: #4C98B3;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        font-family: "Str-Microtext-Regular", sans-serif;
        cursor: pointer;
       }

       .chip-active {
        border: 1px solid #3291B8;
        background-color: #4C98B314;
       }
    }

    .filter-search-desktop{
        display: none;
        margin-top: 16px;
        .filters-search{
            padding: 0;
            margin-bottom: 16px;
            gap: 17px;
            div span{
                font-size: 20px;
                margin-right: 0;
            }
        }
        .container-filters-chips{
            padding: 0;
        }
    }

    .container-head-search{
        display: flex;
        align-items: center;
        gap: 16px;
        button{
            display: none;
        }
    }

    .container-hidden-data {
        padding: 24px 16px;
        img{
            content: url('../../assets/icons/seach-incognito.svg');
        }
        div span {
            display: block;
            &:first-child {
              color: #222222;
              font-size: 18px;
              font-weight: 700;
              line-height: 24px;
              font-family: "Str-Headline-Bold", sans-serif;
            }
            &:last-child {
                font-size: 16px;
                font-weight: 400;
                color: #727272;
                line-height: 24px;
                font-family: "Str-Microtext-Regular", sans-serif;
            }
        }
    }

    @media (min-width: 768px) {
        .container-head-search button{
            display: initial;
        }
        .container-result-search-fixed{
            padding: 0 24px;
        }
        .detail-search{
            padding-top: 64px !important;
        }
        .filter-search-desktop{
            display: initial;
        }
        .container-fixed-results{
            padding: 16px 24px;
        }
        .container-center-result{
            width: 100%;
            max-width: 855px !important;
        }

        .container-fixed-center{
            max-width: 855px !important;
            .filters-search, .container-filters-chips{
                display: none;
            }
            .container-head-search{
                padding-bottom: 32px;
            }
        }
    }

    @media (min-width: 1199px) {
        .container-results {
            max-width: 531px;
        }
    }

    @media (min-width: 1200px) {
        .container-results{
            max-width: none;
           flex: 1;
        }
        .container-center-result{
            max-width: 1392px !important;
        }
        .container-fixed-center{
            max-width: 600px !important;
        }
        .container-result-search-fixed{
            .spacing-desktop{
              display: initial;
              width: 300px;
            }
            .container-fixed-center{
                flex: 1;
                max-width: none !important;
            }
            .container-search-static{
                max-width: 1392px !important;
            }
        }
        .filter-search-desktop{
            padding: 0 24px;
            max-width: 294px;
        }
        .input-search{
            max-width: 448px;
        }
    }

    @media (min-width: 992px) {
        &{
            padding-top: 64px;
        }
    }
}

.container-hidden-data {
    padding: 24px 16px;
    img{
        content: url('../../assets/icons/seach-incognito.svg');
    }
    div span {
        display: block;
        &:first-child {
          color: #222222;
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          font-family: "Str-Headline-Bold", sans-serif;
        }
        &:last-child {
            font-size: 16px;
            font-weight: 400;
            color: #727272;
            line-height: 24px;
            font-family: "Str-Microtext-Regular", sans-serif;
        }
    }
}

.container-loader {
    height: auto;
    img{
        animation: spin 1.2s linear infinite;
        transition-duration: 0.2s;
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg)
        }
    
        100% {
            transform: rotate(360deg)
        }
    }
}