@import "../../../../breakpoints_new";

.card-link {
  border-radius: 12px !important;
  box-shadow: 0px 1px 6px 0px rgba(68, 68, 68, 0.2);
  max-width: 160px;

  &:hover {
    box-shadow: 0px 1px 16px 0px rgba(68, 68, 68, 0.2);
  }

  @media #{$mq-medium} {
    max-width: 100%;
  }

  @media #{$mq-small} {
    max-width: 100% !important;
  }

  &__icon {
    width: 72px;
    height: 72px;
    border-radius: 100%;
    background-color: #F5F9FB;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    @media #{$mq-small} {
      width: 56px;
      height: 56px;
    }

  }

  &__text {
    text-align: start;
    font-size: 20px;
    font-family: 'Str-Headline-Bold', sans-serif;
    line-height: 24px;
  }

  &__alert {
    position: absolute;
    right: 8px;
    top: 8px;
    border-radius: 4px;
    background: #FFCC33;
    padding: 0px 8px;
    font-family: 'Str-Microtext-Bold', sans-serif;
    color: #222222;
    font-size: 14px;
    z-index: 2;
  }
}

