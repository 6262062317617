.accordion {
    display: flex;
    flex-direction: column;
  }
  
  .accordion-item {
    margin-bottom: 10px;
    border-style: none;
  }
  
  .accordion-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 1rem;
  }
  
  .accordion-image {
    margin-right: 10px;
    width: 50px;
  }
  
  .accordion-title {
    flex-grow: 1;
    text-align: center; 
    text-align: left;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  .accordion-item:not(:first-of-type){
    border-style: none;
    border-color:  #ffffff;
}

  
  .accordion-icon {
    font-size: 20px;
    cursor: pointer;
  }
  
  .accordion-description {
    display: none;
    margin-top: 10px;
    transition: max-height 0.8s ease;
  }
  
  .accordion-description.expanded {
    display: block;
    padding: 0 16px 16px 76px;
    margin-top: -12px;
    max-height: 200px;
  }