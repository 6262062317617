@import '../../assets/css/color.scss';
.banner-mujeres{
    background-image: url('../../assets/images/banner-home1.png');
    background-color: #f5f9fb;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 550px;
    width: 100%;
    position: relative;
    display: flex;
    margin-top: 150 ;
   
  }
  .textBannerM{
    display: block;
    color: white !important;
    font-size: 64px;
    font-family: "Str-Headline-Bold", sans-serif;   
    margin-top:10%;
    margin-left: 5%;
    margin-right: 50%;
    text-align: left;
    line-height: 1.5;
    }
    .subTextBannerM{
      display: block;
      color: white ;
      font-size: 28px;
      font-family: "Str-Microtext-Regular", sans-serif;   
      text-align: left;
      line-height: 1.5;
      }
  @media/*  (max-width: 600px) */ screen and (min-width: 320px) and (max-width: 575px) {
    .banner-mujeres{
        background-image: url('../../assets/images/banner_mujeres_movil.png');
        background-color: #f5f9fb;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        
        height: 350px;
        width: 100%;
        position: relative;
        display: flex;
        margin-top: 150 ;
       
      }
      .textBannerM {
        display: block;
        color: white !important;
        font-size: 28px;
        font-family: "Str-Headline-Bold", sans-serif;
        margin-top: 20% ;
        margin-left: 5%;
        text-align: left;
        line-height: 1.5
    }
    .subTextBannerM{
      display: block;
      color: white;
      font-size: 16px;
      font-family: "Str-Microtext-Regular", sans-serif;   
      text-align: left;
      margin-top: 10px;
      line-height: 1.5;
      }
  }
  @media screen and (min-width: 576px) and (max-width: 1000px){
   

    .textBannerM {
      display: block;
      color: white;
      font-size: 24px !important;
      font-family: "Str-Headline-Bold", sans-serif;
      margin-top: 1% ;
      margin-left: 5%;
      text-align: left;
      line-height: 1.5
  }
  .subTextBannerM{
    display: block;
    color: white;
    font-size: 20px;
    font-family: "Str-Microtext-Regular", sans-serif;   
    text-align: left;
    margin-top: 10px;
    line-height: 1.5;
    }
  }
.segment-container{
    padding-bottom: 70px;
    border-bottom-width: 2px;
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-style: solid;
}
.last-segment-container{
    padding-top: 60px;
    padding-bottom: 70px;
}
.title-segment-content-solidario{
    width: 100%;
    padding-bottom: 20px;
}
.body-segment-content-solidario-cw{

  .accordion .card:first-of-type {
    border-bottom: 1px solid rgba(0, 0, 0, 0.175) !important;
  }

    width: 90%;
    margin: 0px auto;

    .nav-tabs{
      .nav-item {
        flex-grow: initial;
      }
    }


    @media (min-width: 768px) {
      .Columna {
        font-size: 18px;
      }

      .download-url{
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
    }
  
    @media (min-width: 1024px) {
      .download-url{
        font-size: 16px;
        gap: 5px;
      }
    }

    @media (min-width: 1100px) {
      .Columna {
        font-size: 28px;
      }
    }

    @media (min-width: 1177px) {
      .nav-tabs{
        .nav-item {
          flex-grow: 1 !important;
        }
      }
    }
    
}

.text-item-card{
    font-size: 16pt;
}

.list-tab-item{
    margin-bottom: 10px;
    font-family: "Str-Microtext-Regular", sans-serif;
    list-style-image: url('./../../assets/images/ic_arrow_right.svg');
}



.titulo{
  font-family: "Str-Headline-Bold", sans-serif;
  font-size: 28px;
  font-style: normal;
  line-height: 28px;
  text-align: left;
  color: #FFFFFF;
  display: flex;
}

@media screen and (min-width: 320px) and (max-width: 575px) {

  .titulo{
      font-family: "Str-Headline-Bold", sans-serif;
      font-size: 24px;
      font-style: normal;
      line-height: 28px;
      text-align: left;
      color: #FFFFFF;
      display: flex;
  }

}

@media screen and (min-width: 576px) and (max-width: 1199px) {





  .titulo{
      font-family: "Str-Headline-Bold", sans-serif;
      font-size: 24px;
      font-style: normal;
      line-height: 28px;
      text-align: left;
      color: #FFFFFF;
      display: flex;
  }

}

.texto{
  
  font-family: "Str-Headline-Bold", sans-serif;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  line-height: 24px;
  text-align: left;
  color: #FFFFFF;
  display: flex;
  
}

.subtext{
  font-family: "Str-Microtext-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  color: #FFFFFF;
  flex-wrap: wrap;
  display: flex;
}
