.Descarga:hover {
  color: #ec0000 !important ;
}
.boxi{
  background-color: #EFF6F8;
  padding-top:100px ;
  padding-bottom: 80px;
 }
.whe-we-are-container-banner{
  display: flex;
  width: 90%;
  justify-content: space-between ;

}
.whe-we-are-image {
  width: 300px;
}
.whe-we-are-text-banner {
  display: block;
  color: #222222 !important;
  font-size: 48px;
  font-family: "Str-Headline-Bold", sans-serif;
  margin-top: 97px;
  text-align: left;
  line-height: 1.5;
}
.whe-we-are-subText-banner{
  display: block;
  color: #444444;
  font-size: 18px;
  font-family: "Str-Microtext-Regular", sans-serif;
  margin-top: 12px !important;
  line-height: 1.5;

}

@media screen and   (max-width: 575px) {
  .whe-we-are-container-banner{
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      text-align: center;
  }
  .whe-we-are-image {
      width: 80%;
      margin: 0 auto;
  }
  .whe-we-are-text-banner {
      display: block;
      color: #222222 !important;
      font-size: 36px !important;
      font-family: "Str-Headline-Bold", sans-serif;
      margin-top: 55px;
      margin-bottom: 25px;
      text-align: center;
      line-height: 32px !important;
  }
  .whe-we-are-container-text{
      width: 100%;
  }
}
@media screen and (min-width: 576px) and (max-width: 1199px) {
  .whe-we-are-subText-banner{
      display: block;
      color: #444444;
      font-size: 16px;
      font-family: "Str-Microtext-Regular", sans-serif;
      margin-top: 12px !important;
      line-height: 1.5;
  
  }
  .whe-we-are-container-banner{
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      width: 100%;
      gap:10px

      
  }
  .whe-we-are-image {
      width: 340px;
      margin: 0 auto;
  }
  .whe-we-are-text-banner {
      display: block;
      color: #222222 !important;
      font-size: 36px !important;
      font-family: "Str-Headline-Bold", sans-serif;
      margin-top: 65px;
      text-align: center;
      line-height: 32px !important;
      margin-bottom: 25px;
      
  }

}
