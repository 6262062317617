.home-link-rojo {
  color: #EC0000;

  font-family: "Str-Microtext-Regular", sans-serif;

}

.home-link-rojo:hover {
  color: #990000 !important;

}

.container-body {
  display: flex;

}
.container-button-deposit-fixed {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 2rem 1rem 3rem;

  button {
    max-width: 264px;
    margin: 0 !important;
  }

  @media (min-width: 768px) {
    padding: 48px 0 48px !important;
    gap: 24px;
  }
}

//banner
.banner-form-dpf {
  background-color: #f0f3f5;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 150;
  @media (min-width: 1024px) {
    height: 304px;
    margin-top: 35px;
  }
}
.container__FormDpfRCF{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dpf-container-banner {
  width: 567px;
  background-color: #f6fafc;
padding-top: 120px;
  height: 100%;
}
.dpf-text-banner {
  display: block;
  color: #222222 !important;
  font-size: 40px;
  font-family: "Str-Headline-Bold", sans-serif;
  margin: 10px 0px;
  line-height: 1.5;
  width: 419px;
  align-items: center;
  text-align: center;
  justify-content: center;
  // margin-bottom: 71px;
}
.description-banner-dft {
  margin-top: 16px;
  color: #444444;
  font-size: 18px;
  line-height: 24px;
  font-family: "Str-Microtext-Regular", sans-serif;
  margin-bottom: 24px;
}

.dpf-image {
  width: 373px;
  margin: 0 auto;
}

.dpf-title {
  font-size: 20px;
  font-family:"Str-Headline-Bold", sans-serif;
  color: #1d1d1d;
  margin-bottom: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.dpf-subtitle {
  font-size: 18px;
  font-family: "Str-Microtext-Regular", sans-serif;
  color: #1d1d1d;
  margin-bottom: 20px;
}
.textBannerL {
  display: block;
  color: #1d252d;
  font-size: 56px;
  font-family: "Str-Headline-Bold", sans-serif;
  margin-top: 15%;
  text-align: left;
}
.message {
  font-size: 12px;
  font-family: "Str-Microtext-Regular", sans-serif;
  color: #979797;

  padding-bottom: 16px;
}
.text-optional {
  margin-top: -12px !important;
}

.mt-paso2-radio-button {
  margin-top: 8px;
}

.formgroup-check-surgir {
  margin-top: 16px;

  @media (min-width: 768px) {
    margin-top: 32px;
  }
}
//form-principal
.form-container-components{
  grid-template-columns: 1fr;
}
.form-container-components-ubigeo{
  grid-template-columns: 1fr;
}
.fxnWNi {
  display: block !important;
}
.position {
  margin-top: 150px;
  width: 80%;
}

.footer-content-trea {
  padding: 10px 20px 10px 60px;
  width: 20%;
}
.footer-content-text {
  padding: 10px 60px 30px 20px;
  width: 80%;
}
.footer-text {
  font-size: 12px;
  font-family: "Str-Microtext-Regular", sans-serif;
  color: #5e5c5c;
}
.banner-text {
  font-size: 14px;
  font-family: "Str-Microtext-Regular", sans-serif;
  color: #5e5c5c;
}
.container-form{
  width: 398px;
  padding-top: 120px
}
.dpf-modalsuccess {
  margin-top: 5%;

  .subtitle_form {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px !important;
  }

  .container-btn-close {
    width: 100%;
    text-align: end;
    button {
      padding: 0;
    }
  }
  .dpf-modal-content {
    max-width: 276px;
    height: 444px;
  }
  .modal-dialog {
    display: flex;
    justify-content: center;
    height: 480px;
  }

  .modal-body {
    padding: 29px 24px 24px;
  }

  @media (min-width: 425px) {
    .modal-dialog {
      height: 444px;
    }
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 360px;
    }
  }

  @media (min-width: 768px) {
    .modal-content {
      max-width: 360px;
      height: 100%;
    }
    .modal-body {
      padding: 37px 32px 32px 32px;
    }
   
  }
}

.modal-confirm {
  margin-top: 5%;

  .title-modal {
    font-family: "Str-Headline-Bold", sans-serif;
    letter-spacing: 0px;
    text-align: center;
    color: #1d252d;
    margin-bottom: 8px;
    font-size: 18px;
    opacity: 1;
  }

  .description-confirm {
    font-family: "Str-Microtext-Regular", sans-serif;
    letter-spacing: 0px;
    color: #1d252d;
    text-align: center;
    opacity: 1;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 18px;
  }

  .container-btn-close {
    width: 100%;
    text-align: end;
    margin-bottom: 8px;
    svg {
      cursor: pointer;
    }
  }
  .modal-content {
    max-width: 272px;
    height: 262px;
    border-radius: 8px;
  }
  .modal-dialog {
    display: flex;
    justify-content: center;
    height: 300px;
  }

  .modal-body {
    padding: 16px 16px 16px 24px;
  }





}
 @media (max-width: 706px) {
 
  .footer-content-trea {
    padding: 1px 1px 1px 1px;
    margin: 0 auto;
    height: 90px;
  }
  .footer-content-text {
    padding: 10px 10px 30px 10px;
    width: 95%;
  }
  .position {
    margin-top: 10px;
    width: 98%;
  }

  .dpf-text-banner {
    font-size: 28px;
    width: 100%;
  }
}
/***************************************************************************************************************
 * ******** ***********************************MOBILE STYLES ***************************************************
 ***************************************************************************************************************/
 @media only screen and (max-width: 574.9px) {
  .container-body {
    
   display: block; 
  }
  .dpf-container-banner {
    width: 100%;
    padding-bottom: 35px;
    
    }
    .container-form{
      width: 90%;
      padding-top: 45px
    }
  .dpf-image {
    width: 70%;
    margin: 0 auto;
  }

 }
  /***************************************************************************************************************
 * ******** ***********************************TABLET 1 STYLES ***************************************************
 ***************************************************************************************************************/

@media only screen and (min-width: 575px) and (max-width: 767.98px) {
  .dpf-container-banner {
    background-color: #f6fafc;
    padding: 100px 10px 70px 10px;
    height: 800px;
    width: 100%;
  }
  .container-body {
    
    display: block; 
  }

  .container-form{
    width: 398px;
    padding-top: 45px
  }
  .modal-content {
    max-width: 376px;
    height: 235px;
  }

  .title-modal {
    text-align: left;
    margin-bottom: 16px;
  }
  .description-confirm {
    text-align: left;
    margin-bottom: 24px;
  }
}
/***************************************************************************************************************
 * ******** ***********************************TABLET 2 STYLES ***************************************************
 ***************************************************************************************************************/

 @media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .container-body {
    display: block; 
    }
  
    .dpf-container-banner {
      width: 100%;
      padding-bottom: 45px;
      }
  
  .form-container-components{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .form-container-components-ubigeo{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
  .container-form{
    width: 572px;
    padding-top: 32px
  }
  }

 
/***************************************************************************************************************
 * ******** ***********************************TABLET 3 STYLES ***************************************************
 ***************************************************************************************************************/
 @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
   
  .dpf-container-banner {
width: 462px;
  height: 900;
}}
