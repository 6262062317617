.container__Cronograma {
    .title-header__Cronograma {
        padding: 60px;
        margin: 0 auto;
        background-color: #23779a;
        margin-bottom: 60px    ;
        .cronograma-title {
            font-size: 48px;
            font-family: "Str-Headline-Bold", sans-serif;
            color: #f7f7f7;
            margin-bottom: 20px;
        }
    }
    .table__Cronograma {
        overflow-x: scroll;
        box-shadow:  0px 0px 5px grey;

        max-width: 1100px;
        margin: 0 auto;
        /* width */
        &::-webkit-scrollbar {
            width: 8px;
            height: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 8px;
            width: 8px;

        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: gray;
            border-radius: 8px;
            width: 8px;
        }
    }
}
