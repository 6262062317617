.title-card {
  font-family: "Str-Headline-Bold", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1d252d;
}

.sub-title-card {
  font-family: "Str-Microtext-Regular", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #444444;
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
}

.link-card {
  font-family: "Str-Microtext-Regular", sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #257fa4;
  // margin-top: 0.75rem;
  cursor: pointer;
}

.container-button {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: center;

  button {
    max-width: 552px;
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  @media (min-width: 1024px) {
    padding: 0;
  }
}

.banner-libro {
  background-image: url('../../assets/images/banner-libro-mobile.png');
  background-color: #f5f9fb;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 150;

  @media (min-width: 1024px) {
    background-image: url('../../assets/images/banner-libro.jpg');
    height: 304px;
    margin-top: 35px;
  }

}

.textBannerL {
  display: block;
  color: #1D252D;
  font-size: 56px;
  font-family: "Str-Headline-Bold", sans-serif;
  margin-top: 15%;
  text-align: left;
}



.mt-paso2-radio-button {
  margin-top: 8px;
}

.formgroup-check-surgir {
  margin-top: 16px;

  @media (min-width: 768px) {
    margin-top: 32px;
  }
}

.modalsuccess {
  margin-top: 5%;

  .subtitle_form {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px !important;
  }

  .container-btn-close {
    width: 100%;
    text-align: end;

    button {
      padding: 0;
    }
  }

  .modal-content {
    max-width: 276px;
    height: 444px;
  }

  .modal-dialog {
    display: flex;
    justify-content: center;
    height: 480px;
  }

  .modal-body {
    padding: 29px 24px 24px;
  }

  @media (min-width: 425px) {
    .modal-dialog {
      height: 444px;
    }
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 360px;
    }
  }

  @media (min-width: 768px) {
    .modal-content {
      max-width: 360px;
      height: 100%;
    }

    .modal-body {
      padding: 37px 32px 32px 32px;
    }

  }
}

.modal-confirm {
  margin-top: 5%;

  .title-modal {
    font-family: "Str-Headline-Bold", sans-serif;
    letter-spacing: 0px;
    text-align: center;
    color: #1D252D;
    margin-bottom: 8px;
    font-size: 18px;
    opacity: 1;
  }

  .description-confirm {
    font-family: "Str-Microtext-Regular", sans-serif;
    letter-spacing: 0px;
    color: #1D252D;
    text-align: center;
    opacity: 1;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 18px;
  }

  .container-btn-close {
    width: 100%;
    text-align: end;
    margin-bottom: 8px;

    svg {
      cursor: pointer;
    }
  }

  .modal-content {
    max-width: 272px;
    height: 262px;
    border-radius: 8px;
  }

  .modal-dialog {
    display: flex;
    justify-content: center;
    height: 300px;
  }

  .modal-body {
    padding: 16px 16px 16px 24px;
  }


  @media (min-width: 768px) {
    margin-top: 10%;

    .modal-content {
      max-width: 376px;
      height: 235px;
    }

    .title-modal {
      text-align: left;
      margin-bottom: 16px;
    }

    .description-confirm {
      text-align: left;
      margin-bottom: 24px;
    }
  }
}