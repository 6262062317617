@import "../../assets/css/color.scss";
.footer-container-top {
    background-color: $primary-white-ffffff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // border-top: thin solid #b4dadf;
}
.container-up-button{
    width: 100%;
    max-width: 1392px;
}
.container-top-footer{
    margin: 0;
    padding: 0 24px;
    width: 100%;
    max-width: 1400px;
}
.footer-container-top-santader {
    background-color: $primary-white-ffffff;
    border-top: thin solid #CEDEE7;
}
.footer-top-surgir {
    width: 90% !important;
    margin: 0 auto !important;
    padding: 30px 0px !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
}
.footer-bottom-surgir {
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin: 30px auto;
    gap: 20px;
}
.footer-top-santander {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px 0px 20px 0px;
    gap: 10px;
}

.footer-container-bottom {
    background-color: $primary-santander-red-ec-0000;
}
.item-footer-link {
    text-decoration: none;
    color: #727272;
    font-family: "Str-Microtext-Regular", sans-serif !important;
    font-weight: 400;
}
.footer-link-rojo {
    color: #ec0000;
    text-decoration: none;
    font-family: "Str-Microtext-Regular", sans-serif;
    &:hover {
        color: #b60303;
        text-decoration: none ;
    }
}
.call-link {
    color: #222222;
    font-size: 20px;
    text-decoration: none ;
    font-family: "Str-Headline-Bold", sans-serif;
    &:hover {
        color: #ec0000;
        text-decoration: none ;
    }
}
.item-footer-link:hover {
    text-decoration: none;
    color: #ec0000;
    
}
.footer-row-surgir {
    display: flex;
    flex-direction: column;
}
.footer-column-surgir {
    width: 263px;
    margin: 0 auto;
    span {
       font-size: 16px;
       padding-bottom: 12px;
    }
    li {
        padding-bottom: 4px;
        a, .parrafo {
            font-family: "Str-Microtext-Regular", sans-serif;
            color: #727272;
            font-weight: 400;
        }
    }
}

.footer-bottom-text-libro {
    font-size: 12px;
    font-family: "Str-Microtext-Regular", sans-serif;
    color: #f6f6f6;
    text-align: center;
    line-height: 16px;
}

.footer-top-v-line {
    display: none;
    border-left: thin solid #cccccc;
    height: 26px;
    margin: 0px 10px 5px 10px;
}
.footer-bottom-v-line {
    border-left: thin solid #ffffff;
    height: 70px;
}
.footer-bottom-v-line-100 {
    border-left: thin solid #ffffff;
}
.visible-destokp {
    display: flex;
}
.visible-mobile {
    display: none;
}
.visible-call-destokp{
    display: none;
}
.visible-call-mobile {
   display: flex !important;
}
.visible-tablet {
    display: none;
}
.footer-button-call {
    display: flex;
    border-radius: 24px;
    padding: 10px 26px 10px 27px;
    margin-top: 20px;
    gap: 10px;
    justify-content: center;
    color: #EC0000;
    box-shadow: 0px 1px 6px 0px #44444433;
    font-family: "Str-Headline-Bold", sans-serif;
}
.action-up {
    position: relative;
    justify-content: center;
    padding: 16px 0px 16px 0px;
    span{
      display: none;
      font-family: 'Str-Microtext-Regular', sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: #727272;
    }
    .line-action {
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #CEDEE7;
        z-index: 1;
    }
    .container-btn-up {
        &::after{
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background-color: $primary-white-ffffff;
            position: absolute;
            top: 5px;
            right: -20px;
        }

        &::before{
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background-color: $primary-white-ffffff;
            position: absolute;
            top: 5px;
            left: -20px;
        }
    }
}

.action-details {
  position: relative;
  z-index: 2;
  font-family: "Str-Microtext-Regular", sans-serif !important;
}

.icon-footer{
    padding-bottom: 3px;
}
.container-logo-surgir{
    width: 100%;
    display: flex;
    justify-content: center;
    img {
        width: 151px;
        height: 48px;
    }
}

.m24 {
    margin: 24px;
}

.container-static-bottom-srg{
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
}

@media only screen and (max-width: 580px) {
    .footer-link-rojo {
        color: #ec0000;
        text-decoration: none;
        font-family: "Str-Microtext-Regular", sans-serif;
        text-align: center;
        justify-content: center;
    }
    .footer-top-santander {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 20px 0px 20px 0px;
        gap: 10px;
    }

    .footer-column-surgir {
        width: 162px;
        margin: 0 auto;
    }
    .footer-row-surgir {
        gap: 20px;
    }
    .footer-bottom-surgir {
        margin: 30px auto;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .visible-destokp {
        display: none;
    }
    .visible-tablet {
        display: none;
    }
    .visible-mobile {
        display: flex;
    }
    .footer-bottom-v-line {
        border-top: thin solid #ffffff;
        width: 60%;
        height: 0px;
    }
    .footer-bottom-v-line-100 {
        border-top: thin solid #ffffff;
        width: 100%;
        height: 0px;
    }
}
@media screen and (min-width: 575px) and (max-width: 1199px) {
    .visible-destokp {
        display: none;
    }
    .visible-mobile {
        display: flex;
    }
    .footer-row-surgir {
        display: flex;
        //flex-direction: column;
        gap: 20px;
    }
    .footer-bottom-v-line {
        border-top: thin solid #ffffff;
        height: 0px;
        display: flex;
        width: 70%;
        flex-shrink: 0;
    }
    .footer-bottom-surgir {
        display: flex;
        align-self: center;
        justify-content: center;
        flex-direction: column;
        margin: 30px auto;
        gap: 20px;
    }
}

@media (min-width: 768px) {
    .footer-top-surgir {
        width: 100% !important;
        margin: 0 auto !important;
        padding: 40px 0px !important;
        display: flex;
        text-align: left;
        flex-direction: row;
        gap: 24px;
        justify-content: flex-start;
    }
    .footer-row-surgir {
        flex-direction: unset;
        gap: 24px;
    }
    
    .footer-column-surgir {
        width: 162px;
    }
    .visible-call-destokp{
        display: flex;
    }
    .visible-call-mobile {
       display: none !important;
    }

    .footer-top-santander {
        display: flex;
        padding: 24px 0;
        margin: 0;
        flex-direction: initial;
        gap: 5px;
        justify-content: flex-start;
    }
    .footer-top-v-line {
        display: initial;
        border-left: thin solid #cccccc;
        height: 26px;
        margin: 0px 10px 5px 10px;
    }

    .action-up {
        justify-content: flex-end;
        span {
            display: initial;
        }
        .line-action{
            position: relative;
            flex: 1;
        }
        .container-btn-up {
            &::after{
                width: 0;
            }
    
            &::before{
                width: 0;
            }
        }
    }

    .action-details{
        display: flex;
        gap: 10px;
        width: 171px;
        justify-content: center;
        align-items: center;
    }

    .call-link {
        font-size: 24px !important;
    }

    .visible-tablet {
        display: flex;
    }
    .visible-mobile {
        display: none;
    }
    .visible-destokp {
       display: none;
    }
    .footer-bottom-surgir {
        padding: 24px;
        margin: 0;
        gap: 20px;

        .detail-top-footer {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .footer-bottom-v-line {
            display: none;
        }
    }

    .container-logo-surgir{
        width: auto;
        img{
            width: 126px;
            height: 40px;
        }
    }
    .divider-footer {
        width: 0.5px;
        height: 40px;
        background-color: #ffffff;
    }
}

@media (min-width: 1199px) {
    .footer-column-surgir {
        width: 269px;
    }
    .visible-mobile {
        display: none;
    }
    .visible-destokp {
       display: none;
    }
}

@media (min-width: 1200px) {
    .footer-column-surgir {
        width: 270px;
    }
    .visible-tablet {
        display: none;
     }
    .visible-destokp {
        display: flex;
    }
    .divider-footer {
        height: 32px;
    }
    .footer-bottom-surgir {
        justify-content: center;
        .top-footer-dskt {
            display: flex;
            gap: 20px;
            align-items: center;
        }
    }
}

@media (min-width: 1440px) {
    .footer-column-surgir{
        width: 330px;
    }
    .container-top-footer{
        padding: 0;
    }
}