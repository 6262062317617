@import '../../breakpoints';

.benefits {
  padding-top: 96px;
  padding-bottom: 96px;
  background-color: #F2F8FA;

  @media #{$mq-tablet3} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media #{$mq-tablet2} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media #{$mq-tablet1} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media #{$mq-mobile} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .span-surgir {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-skip-ink: none;
    text-underline-position: from-font;
    cursor: pointer;
    a {
      color: #ec0000 !important;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media #{$mq-tablet1} {
      grid-template-columns: 1fr;
    }

    @media #{$mq-mobile} {
      grid-template-columns: 1fr;
    }

    &__hero {
      margin: 0px auto;

      img {
        @media #{$mq-tablet2} {
          width: 240px;
        }

        @media #{$mq-mobile} {
          width: 240px;
        }
      
      }
    }

    &__content {
      p {
        color: #444444;
        font-family: "Str-Microtext-Regular", sans-serif;
        margin: 32px 0px;

        @media #{$mq-tablet2} {
          margin-top: 0px;
        }

        @media #{$mq-tablet1} {
          text-align: center;
        }

        @media #{$mq-mobile} {
          text-align: center;
        }
      }

      &__list {
        ul > li {
          color: #444444;
          font-family: "Str-Microtext-Regular", sans-serif;
          margin-bottom: 16px;

          img {
            margin-right: 16px;
          }
        }

        span {
          color: #444444;
          font-family: "Str-Headline-Bold", sans-serif;
        }
        
      }

      &__title {

        @media #{$mq-tablet2} {
          display: none;
        }

        @media #{$mq-tablet1} {
          display: none;
        }

        @media #{$mq-mobile} {
          display: none;
        }
      }
      
      &__button {
        @media #{$mq-tablet1} {
          text-align: center;
        }

        @media #{$mq-mobile} {
          text-align: center;
        }
      }
    }
  }
}

.carousel-savings-account {
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 96px;
  padding-bottom: 96px;
  text-align: center;
  background-color: #F6FAFC;

  @media #{$mq-tablet3} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media #{$mq-tablet1} {
    padding-left: 46px;
    padding-right: 46px;
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media #{$mq-mobile} {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 64px;
    padding-bottom: 64px;
    line-height: 30px;
  }

}

.cards-savings-account {
  text-align: center;
  background-color: #F0F0F0;
  padding-top: 80px;
  padding-bottom: 80px;

  @media #{$mq-tablet3} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media #{$mq-mobile} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  &__title {
    max-width: 862px;
    margin: auto;
  }

  &__cards {
      padding-top: 48px;
      display: grid;
      grid-template-columns: repeat(2, 415px);
      gap: 24px;
      margin: 0 auto;
      justify-content: center;

      @media #{$mq-tablet2} {
        grid-template-columns: repeat(2, auto);
      }

      @media #{$mq-tablet1} {
        grid-template-columns: repeat(1, auto);
      }

      @media #{$mq-mobile} {
        grid-template-columns: repeat(1, auto);
      }

    &__card {
      width: 100%;
      background-color: #FFFFFF;
      height: auto;
      padding: 24px 34px;
      border-radius: 16px;

      @media #{$mq-tablet1} {
        padding: 24px 16px;
      }

      @media #{$mq-tablet1} {
        height: auto;
      }

      @media #{$mq-mobile} {
        height: auto;
      }

      h3, h4 {
        font-family: "Str-Headline-Bold", sans-serif;
        color: #333333;
        
        @media #{$mq-tablet2} {
         font-size: 28px;
      
        }

        @media #{$mq-tablet1} {
          font-size: 28px;
         }

         @media #{$mq-mobile} {
          font-size: 28px;
         }
       
      }

      p {
        color: #444444;
        font-family: "Str-Microtext-Regular", sans-serif;
        height: 46px;

        @media #{$mq-tablet1} {
            height: auto;
         }

         @media #{$mq-mobile} {
          height: auto;
         }
      }

      &__image {
        margin: 24px auto;
        border-bottom: 1px solid #DAE9F0;;
        padding-bottom: 32px;
        

        img {
          width: 100%;
          margin: auto;

          @media #{$mq-tablet1} {
            width: 338px;
            height: 240px;
           }

           @media #{$mq-mobile} {
            width: 240px
           }


        }
      }
    }
  }
}

.tabs-savings-account {
  background-color: #FFFFFF;
  padding-top: 80px;
  padding-bottom: 80px;

  &__requeriments {
    color: #222222;
    font-family: "Str-Microtext-Regular", sans-serif;
    font-size: 16px;
    span {
      font-family: "Str-Headline-Bold", sans-serif;
    }
  
    ul {
      margin: 10px auto;
      padding-left: 20px;
    }
    li {
      list-style-type: initial;
      margin: 10px auto;
    }
  
    p {
      margin-bottom: 10px;
    }
  }

  &__documents {
    margin-left: 34px;
  }

  &__faq {
    
    ul {
      margin-top: 10px;
      padding-left: 20px;
    }
    li {
      list-style-type: initial;
      font-family: "Str-Microtext-Regular", sans-serif;
    }

    h6 {
      font-family: "Str-Microtext-Regular", sans-serif;
    }

    a {
      color: #257FA4;
    }

    span {
      font-family: "Str-Headline-Bold", sans-serif;
    }
  }

}


.titleTablet2 {
  display: none;
  @media #{$mq-tablet2} {
    display: block;
    max-width: 480px;
    margin: auto;
    margin-bottom: 32px;
  }

  @media #{$mq-tablet1} {
    display: block;
    margin: auto;
    margin-bottom: 32px;
  }

  @media #{$mq-mobile} {
    display: block;
    margin: auto;
    margin-bottom: 32px;
  }
}

.h3-first {
  @media #{$mq-tablet2} {
    max-width: 174px;
    margin: auto;
    margin-bottom: 10px;
   }
}
        