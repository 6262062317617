@import '../../../breakpoints';

.principal-title {
    color: #222222;
    font-family: Str-Headline-Bold,sans-serif;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 0px;

    @media #{$mq-tablet1} {
        font-size: 36px;
    }

    @media #{$mq-mobile} {
        font-size: 28px;
        line-height: normal;
    }

}

.principal-subtitle {
    font-size: 18px;
    font-family: Str-Microtext-Regular, sans-serif;
    color: #222222;
    margin-top: 16px;
    margin-bottom: 0px;

    @media #{$mq-tablet2} {
        font-size: 16px;
    }
}
