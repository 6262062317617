@import '../../breakpoints';


.parrafo-rojo {
  font-size: 18px !important;
  font-family: "Str-Headline-Bold", sans-serif;
  color: #f11616 !important;
  margin-bottom: 20px !important;
}

.dpf-banner {

	padding: 114px 0px 90px 0px;
	height: auto;
	&__body {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 32px;
		&__content {
			margin: auto 0px;
			&__title {
				color: #222222;
				font-size: 48px;
				line-height: 60px;
				font-family: "Str-Microtext-Bold", sans-serif;
			}

			&__description {
				margin-top: 16px;
				color: #444444;
				font-size: 18px;
				line-height: 24px;
				font-family: "Str-Microtext-Regular", sans-serif;
			}

			&__footer {
				margin-top: 48px;
				line-height: 0px;
				display: flex;
				gap: 10px;

				&__text {
					color: #727272;
					font-size: 14px;
					line-height: 20px;
					font-family: "Str-Microtext-Regular", sans-serif;
					margin-bottom: 16px;
				}
			}

			&__footer-responsive {
				margin-top: 48px;
				line-height: 0px;

				&__text {
					color: #727272;
					font-size: 14px;
					line-height: 20px;
					font-family: "Str-Microtext-Regular", sans-serif;
					margin-bottom: 16px;
				}

				display: none;
			}
		}

		&__logo {
			margin: auto;
			width: 544px;
			img:first-child {
				width: 100%;
				height: 100%;
			}
		}
	}
}

.span-surgir {
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-decoration-skip-ink: none;
	text-underline-position: from-font;
	cursor: pointer;
	a {
		color: #ec0000 !important;
	}
}

.title-dpf {
	color: #222222;
	font-size: 32px;
	line-height: 36px;
	font-family: "Str-Microtext-Bold", sans-serif;
	text-align: center;
}

.description-safe {
	font-size: 16px;
	color: #444444;
	font-family: "Str-Microtext-Regular", sans-serif;
	text-align: center;
	margin-top: 12px;
	margin-bottom: 32px;
}
.description-dpf {
	font-size: 20px;
	color: #444444;
	font-family: "Str-Microtext-Regular", sans-serif;
	text-align: center;
	margin-top: 12px;
	margin-bottom: -35px;
}
.section-trea {
	background-color: #F2F8FA;
	padding: 60px 0px;

	&__body {
		display: grid;
		grid-template-columns: 1fr 1fr;

		&__content {
			color: #444444;
			max-width: 700px;
			margin: auto 0px;
			&__title {
				font-size: 32px;
				color: #222222;
				font-family: "Str-Microtext-Bold", sans-serif;
				line-height: 40px;
			}
			&__title-responsive {
				font-size: 28px;
				color: #222222;
				font-family: "Str-Microtext-Bold", sans-serif;
				line-height: 36px;
				margin-bottom: 20px;
				display: none;
			}

			&__description {
				margin: 27px auto 8px auto;
				font-size: 18px;
				line-height: 24px;
				font-family: "Str-Microtext-Regular", sans-serif;
			}
			&__text {
				padding-left: 45px;
			
				> ul {
					
					> li {
						
    
						list-style-type: initial !important;
						list-style-position: inside;
						
					}					
				}
					
					
					
				
			}
			&__span {
				font-size: 24px;
				font-family: "Str-Headline-Bold", sans-serif;
			}
			&__button {
				display: flex;
				align-items: left;
				justify-content: left;
				margin-top: 20px;
			}
		}

		&__image {
			width: 479px;
		}
	}
}
.dpf-section-benefits {
	padding-left: 1%;
  padding-right: 1%;
  padding-top: 96px;
  padding-bottom: 96px;
  text-align: center;

  @media #{$mq-tablet1} {
    padding: 64px 48px;
  }
}

table,
th,
td {
	border: none !important;
	margin: 0px;
	border-top-left-radius: 0px;
}

.table > thead > tr > th {
	vertical-align: middle !important;
	padding-bottom: 32px !important;
}

.table > tbody > tr > td {
	border-top: 1px solid #cedee7 !important;
	padding-top: 32px !important;
	padding-bottom: 32px !important;
	vertical-align: middle !important;
}

.section-steps {
	padding: 96px 0px;
	background-color: #F2F2F2 !important;

	&__body {
		grid-template-columns: 1fr 1fr;
		display: grid;
		grid-gap: 80px;
		margin-top: 56px;
		&__steppers {
			margin: auto;

			ol {
				padding-left: 0pc;
				margin-bottom: 0px;
			}

			&__item {
				position: relative;
				display: flex;
				gap: 1rem;

				&:before {
					content: attr(data-content);
					flex: 0 0 var(--circle-size);
					height: var(--circle-size);
					border-radius: 50%;
					border: 1px solid #ec0000;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					color: #444444;
					font-family: "Str-Microtext-Bold", sans-serif;
				}

				&:not(:last-child) {
					&:after {
						content: "";
						position: absolute;
						left: 0;
						top: calc(var(--circle-size) + var(--spacing));
						bottom: var(--spacing);
						transform: translateX(calc(var(--circle-size) / 2));
						width: 1px;
						background-color: #cccccc;
					}
					padding-bottom: 32px;
				}
			}

			&__content {
				color: #444444;

				&__title {
					font-family: "Str-Headline-Bold", sans-serif;
				}

				&__description {
					font-family: "Str-Microtext-Regular", sans-serif;
					line-height: 24px;
					margin-bottom: 0px;
				}

			}
		}
		&__description {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 20px;			
			width: 90%;
		}
		&__button {
			display: flex;
			align-items: left;
			justify-content: left;
			margin-top: 20px;
		}
		&__image {
			img {
				margin-top: 50px;
				width: 90%;
			}
		}
	}
}

.section-protect {
	padding: 96px 0px;
	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__cards {
		margin-top: 50px;
		&__card {
			background-size: cover;
			height: 360px;
			position: relative;

			&__content {
				position: absolute;
				bottom: 0px;
				padding: 16px;
			}
		}
	}
}

.section-simula {
	padding: 50px 0px;
	background-color: #f6fafc;
	&__body {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 180px;
		margin-left: 6px;

		&__content {
			color: #444444;
			max-width: 550px;
			margin: auto 0px;
			&__title {
				font-size: 32px;
				color: #222222;
				font-family: "Str-Headline-Bold", sans-serif;
				line-height: 42px;
			}
			&__description {
				margin: 20px auto 8px auto;
				font-size: 18px;
				line-height: 24px;
				font-family: "Str-Microtext-Regular", sans-serif;
			}

			&__span {
				font-size: 24px;
				font-family: "Str-Headline-Bold", sans-serif;
			}
		}

		&__image {
			width: 420px;
		}
	}
}
.dpf-section-information {
	background: white !important;
	padding: 96px 0px;

	&__tabs {
		&__tab-requirements {
			
			padding-left: 16px;

			ul {
				padding-left: 2rem;
				margin-bottom: 0px;
				font-family: "Str-Microtext-Regular", sans-serif;
				color: #444444;
				text-align: left;
			}
			li {
				list-style-type: initial;
			}
		}

		&__list-order {
			li {
				list-style-type: auto !important;
			}
		}
		&__list-no-order {
			ul {
				padding-left: 2rem;
				margin-bottom: 0px;
				font-family: "Str-Microtext-Regular", sans-serif;
				color: #444444;
			}
			li {
				list-style-type: initial;
			}
		}

		&__link {
			display: flex;
			margin-top: 28px;

			&__body {
				cursor: pointer;
				width: max-content;
				justify-content: center;
				display: flex;
				align-items: center;
				color: #ec0000;
				h6 {
					margin-bottom: 0px;
					text-decoration: underline;
					text-decoration-thickness: 1px;
					text-decoration-skip-ink: none;
					text-underline-position: from-font;
				}
				span {
					margin-left: 8px;
				}
			}
		}

		
	}

	&__tabs-responsive {
		margin-top: 48px;
	}
}

.tab-channels {
	display: flex;
	gap: 24px;
	margin: 0px auto;
	&__card {
		background-color: #f6fafc;
		width: 324px;
		height: 173px;
		border-radius: 8px;
		padding: 16px;
		text-align: center;
		&__span {
			font-size: 24px;
			font-family: "Str-Headline-Bold", sans-serif;
		}
		&__image {
			margin: 0 auto;
		}
		&__hr {
			display: block;
		}
	}
}

@media only screen and (max-width: 585px) {
	.table > thead {
		display: none;
	}

	.table > tbody > tr > td {
		border-top: none !important;
		padding: 0px !important;
	}

	.table > tbody > tr > td:nth-child(1) {
		padding-top: 32px !important;
		padding-bottom: 16px !important;
	}

	.table > tbody > tr:nth-child(even) {
		border-bottom: 1px solid #cedee7;
	}

	.table > tbody > tr:nth-child(even):last-child {
		border-bottom: none;
	}

	.table > tbody > tr:nth-child(even) > td {
		padding-bottom: 32px !important;
	}

	.tab-channels {
		display: block;
		gap: 24px;

		&__card {
			background-color: #f6fafc;
			display: flex;
			width: 280px;
			border-radius: 8px;
			padding: 20px 10px;
			&__span {
				font-size: 24px;
				font-family: "Str-Headline-Bold", sans-serif;
			}
			&__image {
				width: 21px;
			}
			&__line {
				border-left: thin solid rgb(212, 212, 212);
				height: 92px;
				margin: 0px 20px 0px 20px;
				display: block;
			}
		}
	}
	.dpf-banner {
		padding: 72px 0px 43px 0px;
		&__body {
			grid-template-columns: 1fr;
			text-align: center;
			grid-gap: 20px;
			&__content {
				&__title {
					color: #222222;
					font-size: 28px;
					font-family: "Str-Microtext-Bold", sans-serif;
				}

				&__description {
					margin: auto 18px;
					font-size: 16px;
				}

				&__footer {
					display: none;
				}

				&__footer-responsive {
					display: block;
				}
			}

			&__logo {
				width: 80%;
			}
		}
	}
	.section-trea {
		padding: 60px 0px;

		&__body {
			display: grid;
			grid-template-columns: 1fr;
			text-align: center;
			max-width: 270px;
			margin: auto;
			&__content {
				color: #444444;
				max-width: 700px;
				margin: auto 0px;
				&__title {
					display: none;
				}
				&__title-responsive {
					font-size: 28px;
					color: #222222;
					font-family: "Str-Microtext-Regular", sans-serif;
					line-height: 36px;
					display: block;
				}

				&__description {
					margin: 40px auto 8px auto;
					font-size: 18px;
					line-height: 24px;
					font-family: "Str-Microtext-Regular", sans-serif;
				}
				&__text {
					margin-left: 60;
					list-style-type: initial;
					text-align: left;
					> ul {
					
					> li {
						list-style-type: initial !important;
						list-style-position: inside;
					}
				}}

				&__span {
					font-size: 24px;
					font-family: "Str-Headline-Bold", sans-serif;
				}
				&__button {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 20px;
				}
			}

			&__image {
				width: 100%;
				margin: 0 auto;
			}
		}
	}

	.section-benefits {
		padding: 64px 0px;
		&__cards {
			grid-template-columns: 1fr;
		}
	}

	.section-protect {
		padding: 64px 0px;
		&__cards {
			padding-left: 16px;
			width: 100%;
			height: 410px;
			display: grid;
			grid-auto-flow: column;
			overflow-x: scroll;
		}
	}

	.section-steps {
		padding: 64px 0px;
		&__body {
			grid-template-columns: 1fr;
			grid-gap: 56px;

			&__image {
				width: 90%;
				margin: auto;

				img {
					margin-top: 0px;
					width: 100%;
				}
			}
		}
	}

	.section-simula {
		&__body {
			grid-template-columns: 1fr;
			grid-gap: 32px;
			&__content {
				text-align: center;
				max-width: 270px;
				margin: auto;

				&__title {
					font-size: 28px;
					line-height: 32px;
				}
			}

			&__image {
				width: 90%;
				margin: auto;
				padding-left: 20px;
				img {
					margin-top: 0px;
					width: 100%;
				}
			}
		}
	}

	.section-plans {
		padding: 64px 0px;
		&__cards {
			margin: 48px auto 12px auto;
			&__card {
				padding: 8px;
			}
		}
	}

	.section-toppings {
		padding: 64px 0px;
		&__carousel-toppings {
			padding-left: 16px;
		}

		&__footer {
			margin: 48px 18px 0px 18px;
		}
	}

	.dpf-section-information {
		padding: 64px 0px;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767.98px) {
	// .section-santander {
	// 	padding: 80px 10px;
	// 	display: block;
	// 	width: 90%;
	// 	&__line {
	// 		display: none;
	// 	}
	// }
	.section-trea {
		padding: 60px 0px;

		&__body {
			display: block;

			&__content {
				color: #444444;
				max-width: 700px;
				margin: auto 0px;
				&__title {
					font-size: 42px;
					color: #222222;
					font-family: "Str-Headline-Bold", sans-serif;
					line-height: 52px;
				}

				&__description {
					margin: 40px auto 8px auto;
					font-size: 18px;
					line-height: 24px;
					font-family: "Str-Microtext-Regular", sans-serif;
				}

				&__span {
					font-size: 24px;
					font-family: "Str-Headline-Bold", sans-serif;
				}
				&__button {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 20px;
				}
				&__text {
					list-style-type: initial;
					> li {
						list-style-type: initial;
					}
				}
			}
		}
	}
	.title-dpf {
		font-size: 28px;
	}
	.dpf-banner {
		padding: 116px 0px 56px 0px;
		&__body {
			grid-template-columns: 1fr;
			text-align: center;
			grid-gap: 54px;
			&__content {
				&__description {
					width: 400px;
					margin: 16px auto 0px auto;
				}

				&__footer {
					display: none;
				}

				&__footer-responsive {
					display: block;
				}
			}
			&__logo {
				width: 340px;
			}
		}
	}
	.section-protect {
		&__cards {
			width: 90%;
			height: 430px;
			display: grid;
			grid-auto-flow: column;
			overflow-x: scroll;
		}
	}

	.section-benefits {
		padding: 80px 0px;
		&__cards {
			grid-template-columns: 1fr 1fr 1fr;
			grid-gap: 32px;

			&__card {
				p {
					width: 144px;
				}
			}
		}
	}
	.section-steps {
		padding: 64px 0px;
		&__body {
			grid-template-columns: 1fr;
			grid-gap: 56px;

			&__image {
				width: 90%;
				margin: auto;

				img {
					margin-top: 0px;
					width: 100%;
				}
			}
		}
	}

	.section-simula {
		&__body {
			grid-template-columns: 1fr;
			grid-gap: 32px;
			&__content {
				text-align: center;
				max-width: 270px;
				margin: auto;

				&__title {
					font-size: 28px;
					line-height: 32px;
				}
			}

			&__image {
				width: 90%;
				margin: auto;
				padding-left: 10%;
				img {
					margin-top: 0px;
					width: 100%;
				}
			}
		}
	}

}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
	// .section-santander {
	// 	padding: 80px 10px;
	// 	display: flex;
	// 	width: 700px;
	// }
	.section-protect {
		&__cards {
			width: 100%;
			height: 430px;
			display: grid;
			grid-auto-flow: column;
			overflow-x: scroll;
			padding-left: 2%;
			padding-right: 2%;
			margin-top: 20px;
		}
	}

	.dpf-banner {
		&__body {
			&__logo {
				width: 340px;
			}
		}
	}
	.section-trea {
		padding: 60px 0px;

		&__body {
			&__image {
				width: 340px;
			}
			&__content {
				&__text {
					list-style-type: initial;
					> li {
						list-style-type: initial;
					}
				}
			}
		}
	}
	.section-simula {
		&__body {
			grid-template-columns: 1fr 1fr;
			display: grid;
			grid-gap: 48px;

			&__image {
				width: 332px;
				margin: 0 auto;
				img {
					width: 100%;
				}
			}
		}
	}
}

@media only screen and (min-width: 2320px) {
	.section-toppings {
		&__carousel-toppings {
			margin-top: 48px;
			justify-content: center;
		}
	}
}
