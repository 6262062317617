.banner-canales {

  position: relative;
  padding-top: 64px;
  
  img {
      width: 100%;
  }

  &__banner-desktop {
      display: block;
  }

  &__banner-tablet {
      display: none;
  }

  &__banner-mobile {
      display: none;
  }

  &__title {
      color: white;
      font-size: 52px;
      font-family: "Str-Headline-Bold" , sans-serif;
      display: flex;
      align-items: center;
      margin: 0px;
      margin-left: 5%;
      position: absolute;
      top: 50%;
  }
}

.canales-digitales {
  &__header {
      text-align: center;
      margin: 0px auto 56px auto;

      &__title {
          font-size: 24pt;
          font-family: "Str-Headline-Bold" , sans-serif ;
          color: #9E3667;
          line-height: 48px;
      }

      &__description {
          font-family: "Str-Microtext-Regular", sans-serif;
          font-size: 24px;
          margin: 0px;
          line-height: 32px;
      }

  } 
}

.channels{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  gap: 32px;
  margin:auto;
  

  &__square-channels {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100px;
      height: 100px;
      border-radius: 8px;
      transition: transform 0.5s;
      cursor: pointer;

      &__logo-square{
          margin: auto 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
              width: 100%;
          }
      }
  }

  .interbank {
      filter: drop-shadow(0px 8px 16px rgba(5, 190, 80, 0.3));
  }

  .kasnet {
      filter: drop-shadow(0px 8px 16px rgba(27, 76, 144, 0.3))
  }

  .kasnet-surgir {
      filter: drop-shadow(0px 8px 16px rgba(161, 161, 161, 0.3));
      border: 0.568182px solid #CCCCCC;
  }

  .bcp {
      filter: drop-shadow(0px 8px 16px rgba(0, 67, 136, 0.3));
  }

  .banbif {
      filter: drop-shadow(0px 8px 16px rgba(32, 163, 255, 0.3));
  }

  &__square-channels:hover {
      transform: translateY(-10%);
      transition: transform 0.5s;
      filter: none;
  }
}

.channel-section{
  margin-top: 48px;
}

.accordion-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #CEDEE7;
  padding-bottom: 72px;
  padding-top: 72px;

  &__accordion {
      width: 552px;
      &__interbank {
          background: #05BE50
      }
      &__yape {
          background: #742384
      }
  
      &__kasnet {
          background: #1B4C90 
      }
  
      &__kasnet-olivos {
          border: 0.590909px solid #CCCCCC
      }
      &__bcp {
          background: #004388
      }
      &__banbif {
          background-color: #20A3FF;
      }
  }
}

.header{
  height: 104px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.divider {
  margin-bottom: 5rem;
  border: 0;
  border-top: 0px solid;
}

.button-scroll {
  border-radius: 100%;
  cursor: pointer;
  width: 48px;
  height: 48px;
  background: #990000;
  box-shadow: 0px 2px 2px rgba(29, 37, 45, 0.2);
  position: fixed;
  bottom: 16px;
  right: 36px;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
      width: 24px;
      height: 24px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 1199px) {
  .banner-canales {
      padding-top: 60px;
      &__title {
          font-size: 40px;
      }

      &__banner-desktop {
          display: none;
      }
  
      &__banner-tablet {
          display: block;
      }
  
      &__banner-mobile {
          display: none;
      }
  }
  
  .canales-digitales {
      &__header {
          margin: 0px 0px 48px 0px;
          &__title {
              font-size: 32px;
          }
      } 
  }

  .channels{
      width: auto;
      gap: 34px;

      &__square-channels {
          width: 80px;
          height: 80px;
      }
  }

  .channel-section {
      margin-top: 40px;
  }

  .accordion-section {
      margin-bottom: 56px;
      padding-bottom: 56px;
  }

  .button-scroll {
      right: 12px;
  }

}

@media only screen and (max-width: 575px) {
  .banner-canales {
      
      padding-top: 60px;

      &__title {
          font-size: 28px;
      }

      &__banner-desktop {
          display: none;
      }
  
      &__banner-tablet {
          display: none;
      }
  
      &__banner-mobile {
          display: block;
      }
  }
  .canales-digitales {
      &__header {
          margin: 0px 16px 56px 16px;

          &__title {
              line-height: 36px;
          }
          &__description {
              font-size: 20px;
          }
      }
  }

  .channels {
      padding: 0px 16px;
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      gap: 16px;

      &__square-channels {
          width: 80px;
          height: 80px;
      }
  }

  .accordion-section{
      &__accordion {
          width: 100% !important;
      } 
  }

  .channel-section {
      margin-top: 16px;
  }
      
  .button-scroll {
      right: 16px;
  }

}