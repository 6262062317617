.wrapper{
    padding-left: 75px;
    padding-right: 75px;  
}

.fq-header{
    border-bottom: 1px solid #cccccc;
    display: flex;
    flex-direction: column;
    padding-top: 90px;
    padding-bottom: 40px ;
}

.fq-title{
    color: #9e3667;
    font-size: 32pt;
    font-family: Str-Headline-Bold, sans-serif;
   
}
.fq-sub-title{
    font-size: 18px;
    font-family: Str-Microtext-Regular, sans-serif;
   
}


.nav-pills .nav-link{
    color: #9bc3d3;
    font-size: 20px;
    cursor: pointer;
    display: block;
    padding: 13px 26px;
    background-color: #f5f9fb;
    margin-top: 10px;
    font-family: Str-Microtext-Bold, sans-serif;
}
.nav-pills  .nav-link.active{
    border-right: 4px solid #257fa4;
    background-color: #deedf2;
    padding: 13px 26px;
    color: #444444;

}
.nav-pills .nav-link:hover{
    border-right: 4px solid #257fa4;
    background-color: #deedf2;
    padding: 13px 26px;
    color: #444444;
}

.accordion-item{
    margin-top: 8px;
    border-radius: 4px;
    font-family: Str-Microtext-Regular , sans-serif;

}
.accordion-item:last-of-type {
    border-top-width: 1px;
    border-top-style: solid;
    border-color:  #CCCCCC;
    
}
.accordion-item:not(:first-of-type){
    border-top-width: 1px;
    border-top-style: solid;
    border-color:  #CCCCCC;
    
}



.accordion-button::after{
    background-image: url("../../assets/ic_arrow_down.svg");
}
.accordion-button:not(.collapsed)::after {
    background-image: url("../../assets/images/IconsAbierto.svg");
    transform: rotate(0deg) !important;
}
.accordion-button:focus {
    box-shadow:none !important;
  
}

.accordion-button:not(.collapsed) {
    color:black;
    background-color: white;
   
}
.accordion-body {
    padding: 1rem 1.25rem;
    background:#f5f9fb;
}
