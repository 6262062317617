.data-protection {
	p {
		color: #444444;
	
		span {
			text-decoration: underline;
			color: #257fa4
		}
	}	
}

.card-style-transparence {
  box-sizing: border-box;
  padding: 24px 32px ;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  display: flex;
  width: 552px;
  margin-left: 5px;
}

.card-style-transparence {

	@media (max-width:768px) {
		width: auto;
	}
}

.container-acordion-responsive {
  display: flex;
  justify-content: center;
  .acordion-sg{
    width: 100%;
    max-width: 480px;
  }
}