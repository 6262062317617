.container__Simulador{
  margin: 0 auto;
  padding-top: 63px;
  padding-bottom: 100px;
} 

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
    background-color: gray;
}
.simulator-table{
  box-shadow: 1px 1px 7px 1px rgba(165, 164, 164, 0.2);
}
.simulator-title{
    font-size: 48px ;
    font-family: "Str-Headline-Bold", sans-serif;
    color:#222222 ;
    margin-bottom: 20px ;
}

.simulator-row{
    display: flex;
    gap: 24px;
}
.simulator-col{
    display: block;
    width: 680px; 
   
}
.simulator-elemenent-row{
    width: 328px;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
    background: transparent !important;
}

  .results-table-title{
    font-size: 16px !important;
    font-family: "Str-Headline-Bold", sans-serif;
    color:#444444 !important;
    margin-bottom: 20px !important;
  }
  .results-table-title-white{
    font-size: 16px !important;
    font-family: "Str-Headline-Bold", sans-serif;
    color:#ffffff !important;
    margin-bottom: 20px !important;
  }

  .results-turquoise-row {
    background-color: #23779A;
    color: white;
    
  }
  
  .light-blue-row {
    background-color: lightblue;
  }
  
  .white-row {
    background-color: white;
  }
  
  .blue-row {
    background-color: #CBDEE8;
    color: white;
    width: 608px;
    
  }

.subtitle_schedule{
  font-size: 16px !important;
  font-family: "Str-Headline-Bold", sans-serif;
  color:#1d252d !important;
  margin-bottom: 20px !important;
}

.subtext_schedule{
  font-size: 12px !important;
  font-family: "Str-Microtext-Regular", sans-serif;
  color:#727272 !important;
  width: 1100px;
  margin: auto;
  
}
.schedule-box {
    width: 1120px;
   
    display: flex;
    margin: auto;
}
.turquoise-row {
    background-color: #CBDEE8;
    color: rgb(7, 6, 6);
  }

 
  @media (max-width: 575px) {
    .subtext_schedule{
      font-size: 12px !important;
      font-family: "Str-Microtext-Regular", sans-serif;
      color:#727272 !important;
      width: 90%;
      
    }
    .schedule-box  {
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        overflow-x: scroll;
        padding-left: 2%;
        padding-right: 2%;
        margin-top: 20px;
    }
    .simulator-row{
        display: block;
        gap: 24px;
    }
    .simulator-col{
        display: block;
        width: 300px; 
       
    }
    #grid {
        display: grid;
        height: 200px;
        grid-template: repeat(1, 1fr) / repeat(1, 1fr);
        grid-gap: 16px 24px;
      }
      .simulator-title{
        font-size: 36px ;
        font-family: "Str-Headline-Bold", sans-serif;
        color:#222222 ;
        margin-bottom: 20px ;
    }
    .cronograma-title{
        font-size: 36px ;
        font-family: "Str-Headline-Bold", sans-serif;
        color:#f7f7f7 ;
        margin-bottom: 20px ;
    }
  }
  @media screen and (min-width: 575px) and (max-width: 1199px){
    .schedule-box{
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        overflow-x: scroll;
        padding-left: 2%;
        padding-right: 2%;
        margin-top: 20px;
    }
    .subtext_schedule{
      font-size: 12px !important;
      font-family: "Str-Microtext-Regular", sans-serif;
      color:#727272 !important;
      width: 90%;
      
    }
}