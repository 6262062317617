.static-container-search {
    position: fixed;
    top: 95px;
    z-index: 10;
    width: 100%;
    height: 100%;
}
.show-buscador {
    opacity: 1 !important;
}
.container-search {
    opacity: 0;
    background-color: #DEEDF2E5;
    width: 100%;
    height: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;
    transition: all 0.6s cubic-bezier(0.42, 0, 0.58, 1);

    button {
        display: none;
    }

    .container-input-search{
        position: relative;
        display: flex;
        gap: 16px;
        align-items: center;
        .options-float{
            position: absolute;
            overflow: auto;
            background-color: #ffffff;
            box-shadow: 0px 1px 10px 0px #44444433;
            width: 100%;
            height: 306px;
            border-radius: 8px;
            top: 64px;

            ::-webkit-scrollbar {
                width: 28px; /* Ancho del scrollbar */
            }
    
            ::-webkit-scrollbar-track {
                background: #f1f1f1; /* Color de fondo del track */
                border-radius: 8px; /* Radio del borde del track */
            }
            .item-option{
                display: flex;
                cursor: pointer;
                gap: 8px;
                padding: 16px;
                border-bottom: 1px solid #CEDEE7;
                justify-content: space-between;
                .ic-arrow-right{
                    content: url('../../../assets/icons/arrow-right-gray.svg');
                }
                .ic-download {
                    content: url('../../../assets/icons/icon-download.svg');
                }
                .iconPdf {
                    content: url('../../../assets/images/pdf.svg');
                }
                .iconExcel {
                    content: url('../../../assets/icons/xls-icon-file.svg');
                }
                .detail-option{
                    display: flex;
                    gap: 8px;
                    div{
                        display: flex;
                        flex-wrap: wrap;
                    }
                    span {
                        width: 100%;
                        color: #222222;
                        &:first-child{
                            font-size: 18px;
                            font-weight: 700;
                            line-height: 24px;
                            font-family: "Str-Headline-Bold", sans-serif;
                        }
                        &:last-child{
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                            font-family: "Str-Microtext-Regular", sans-serif;
                        }
                    }
                    
                }
            }
        }
    }

    .input-search{
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        padding: 12px;
        border: 2px solid #3291B8;
        box-shadow: 0px 0px 0px 0px #44444400;
        border-radius: 8px;
        background-color: #ffffff;
        flex: 1;
        input {
            border: none;
            outline: none;
            flex: 1;
        }
        .ic-search {
            content: url('../../../assets/icons/search-icon.svg');
        }
        .ic-clear {
            cursor: pointer;
            content: url('../../../assets/icons/clear-icon-circle-blue.svg');
        }
    }

    .accesos{
        margin-top: 24px;
        span {
            color: #222222;
            font-weight: 700;
            font-size: 18px;
            font-family: "Str-Headline-Bold", sans-serif;
            margin-bottom: 8px;
        }

        .container-tags{
            width: 100%;
            overflow: auto;
            .scroll-tags{
                width: fit-content;
                display: flex;
                gap: 10px;
                overflow: auto;
                padding: 8px 0;
                flex-wrap: wrap;
            }
            .tag-label {
                width: max-content;
                background-color: #ffffff;
                box-shadow: 0px 1px 6px 0px #44444433;
                border-radius: 32px;
                padding: 6px 8px;
                color: #4C98B3;
                font-size: 14px;
                line-height: 20px;
                font-weight: 700;
                font-family: "Str-Microtext-Regular", sans-serif;
                text-decoration: none;
            }
        }
    }

    .fixed-container-search {
        width: 100%;
        max-width: 823px;
    }
    @media (min-width: 375px) {
        .container-input-search{
            .options-float{
                height: 306px;
            }
        }
    }

    @media (min-width: 768px) {
        &{
            button {
                display: initial;
            }
        }
    }

    @media (min-width: 992px) {
        &{
            top: 90px;
        }
    }


    @media (min-width: 1200px) {
        &{
            padding: 24px;
            .fixed-container-search {
                padding: 16px;
            }
        }
        .options-float{
            height: 306px;
        }
    }
}

.container-top-responsive {
    top: 48px;
    @media (min-width: 728px) {
        top: 42px;
    }
    @media (min-width: 1200px) {
        top: 62px;
    }
}

.container-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    height: 100%;
    div {
      width: 74px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img{
        animation: spin 1.2s linear infinite;
        transition-duration: 0.2s;
        content: url('../../../assets/icons/loader-icon.svg');
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg)
        }
    
        100% {
            transform: rotate(360deg)
        }
    }
}