@import '../../breakpoints';

.dpff {
  h1 {
    color: #222222;
  }

  
}
.dpff-benefits {
  background-color: #F2F8FA;
  padding: 96px 0px;

  @media #{$mq-tablet2} {
    padding: 64px 0px;
  }

  @media #{$mq-tablet1} {
    padding: 64px 0px;
  }

  .span-surgir {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-skip-ink: none;
    text-underline-position: from-font;
    cursor: pointer;
    a {
      color: #ec0000 !important;
    }
  }
  
  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media #{$mq-tablet1} {
      grid-template-columns: 1fr;
    }

    @media #{$mq-mobile} {
      grid-template-columns: 1fr;
    }

    &__hero {
      text-align: center;

      img {
        @media #{$mq-tablet2} {
          width: 240px;
        }

        @media #{$mq-mobile} {
          width: 240px;
        }
      
      }

    }

    &__content {
      p {
        color: #444444;
        font-family: "Str-Microtext-Regular", sans-serif;
        margin: 32px 0px;

        @media #{$mq-tablet2} {
          margin-top: 0px;
          margin-bottom: 24px;
        }

        @media #{$mq-tablet1} {
          text-align: center;
        }

        @media #{$mq-mobile} {
          text-align: center;
        }
      }

      &__list {
        ul > li {
          color: #444444;
          font-family: "Str-Microtext-Regular", sans-serif;
          margin-bottom: 16px;

          img {
            margin-right: 16px;
          }
        }

        span {
          color: #444444;
          font-family: "Str-Headline-Bold", sans-serif;
        }

        &__strong-red {
          color: #EC0000 !important;
        }
        
      }

      &__title {

        @media #{$mq-tablet2} {
          display: none;
        }

        @media #{$mq-tablet1} {
          display: none;
        }

        @media #{$mq-mobile} {
          display: none;
        }
      }

      &__button {
        @media #{$mq-tablet1} {
          text-align: center;
        }

        @media #{$mq-mobile} {
          text-align: center;
        }
      }

    }
  }

  

}

.dpff-benefits-carousel {
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 96px;
  padding-bottom: 96px;
  text-align: center;

  @media #{$mq-tablet1} {
    padding: 64px 48px;
  }

}

.dpff-carousel {
	padding: 96px 0px;

	&__cards {
		margin-top: 56px;

		&__card {
			
			color: #444444;
			font-size: 16px;
			line-height: 24px;
			font-family: "Str-Microtext-Regular", sans-serif;
			height: 190px;
			display: flex;
			align-items: top;
			justify-content: center;

			div {
				text-align: center;
			}

			img {
				width: 40px !important;
				height: 40px;
			}

      h4 {
        font-family: "Str-Microtext-Bold", sans-serif;
        color: #333333;
        margin: 12px auto;
      }

			p {
				width: 250px;
				padding: 0px 8px;
				line-height: 18px;
        font-size: 14px;
			}
		}
	}
}

.dpff-respaldo {
  padding-left: 12%;
  padding-right: 12%;
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  font-family: "Str-Microtext-Regular", sans-serif;

  &__logo {
    text-align: center;

    span {
      color: #727272;
      font-size: 14px;
    }
  }

  &__description {
    display: flex;
    align-items: center;
    color: #444444;
    width: 588px;
  }
}

.dpff-steps {
  padding: 96px 0px;
  background-color: #F2F2F2;

  @media #{$mq-tablet2} {
    padding: 64px 0px;
  }

  @media #{$mq-tablet1} {
    padding: 64px 0px;
  }

  @media #{$mq-mobile} {
    padding: 64px 0px;
  }

  &__header {

    h5 {
      margin-top: 16px;
      margin-bottom: 0px;
      font-family: "Str-Microtext-Regular", sans-serif;
      color: #444444;
      text-align: center;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 32px;

    @media #{$mq-tablet1} {
      grid-template-columns: 1fr;
    }

    @media #{$mq-mobile} {
      grid-template-columns: 1fr;
    }

    &__image {
      text-align: center;

      img {
        @media #{$mq-tablet2} {
          width: 240px;
        }

        @media #{$mq-tablet1} {
          width: 340px;
        }

        @media #{$mq-mobile} {
          width: 240px;
        }
      }
    }
    
    &__steps {
      
      &__step {
        display: grid;
        grid-template-columns: auto 1fr;
        height: 92px;

        &__title {
          color: #222222;
          font-family: "Str-Headline-Bold", sans-serif;
          font-size: 20px;
       
        }

        &__description {
          color: #444444;
          font-family: "Str-Microtext-Regular", sans-serif;
          margin-bottom: 0px;
        
        }

        &__index {
          border-radius: 100%;
          border: 1px solid #EC0000;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 16px;
          position: relative;
          color: #444444;
          font-family: "Str-Headline-Bold", sans-serif;
          font-size: 14px;

          &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 41px;
            background-color: #CCCCCC;
            top: 40px;
            right: 14px;
          }

          &.no-line::after {
            display: none;
          }
          
        }
        
        &:last-child {
          height: auto;
        }

        @media (min-width: 577px) {
          height: auto;
        }

        @media (min-width: 768px) {
          height: 92px;
        }

      }

      &__footer {
        color: #444444;
        font-family: "Str-Microtext-Regular", sans-serif;
        
        @media #{$mq-tablet1} {
          text-align: center;
        }

        @media #{$mq-mobile} {
          text-align: center;
        }
      }

      &__button {
        @media #{$mq-tablet1} {
          text-align: center;
        }

        @media #{$mq-mobile} {
          text-align: center;
        }
      }
    } 
  }
}

.dpff-simulation {
  background-color: #F2F8FA;
  padding: 96px 0px;

  @media #{$mq-tablet2} {
    padding: 64px 0px;
  }

  @media #{$mq-tablet1} {
    padding: 64px 0px;
  }

  @media #{$mq-mobile} {
    padding: 64px 0px;
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    
    @media #{$mq-tablet1} {
      grid-template-columns: 1fr;
    }

    @media #{$mq-mobile} {
      grid-template-columns: 1fr;
    }
    
    
    &__text {
      @media #{$mq-tablet1} {
        text-align: center;
      }

      @media #{$mq-mobile} {
        text-align: center;
      }
    }

    &__image {
      display: flex;
      justify-content: end;

      @media #{$mq-tablet1} {
        justify-content: center;
      }

      @media #{$mq-mobile} {
        justify-content: center;
      }

      img {
        @media #{$mq-tablet2} {
          width: 300px;
        }

        @media #{$mq-tablet1} {
          width: 340px;
          margin-top: 32px;
        }

        @media #{$mq-mobile} {
          width: 240px;
          margin-top: 32px;
        }
      }
    }
  }
 
}

.dpff-about {
  padding: 96px 0px;
  
  @media #{$mq-tablet1} {
    padding: 64px 0px;
  }

  @media #{$mq-tablet3} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  &__faq {

  }
}

.tab-requeriments {
  color: #444444;
  font-family: "Str-Microtext-Regular", sans-serif;
  font-size: 16px;
  span {
    font-family: "Str-Headline-Bold", sans-serif;
  }

  ul {
    margin: 10px auto;
    padding-left: 20px;
  }
  li {
    list-style-type: initial;
    margin: 10px auto;
  }

  p {
    margin-bottom: 10px;
  }
}

.tab-documents {
  margin-left: 34px;
}

.tab-faq {
   ul {
      margin-top: 10px;
      padding-left: 20px;
    }
    li {
      list-style-type: initial;
      font-family: "Str-Microtext-Regular", sans-serif;
    }

    h6 {
      font-family: "Str-Microtext-Regular", sans-serif;
    }

    a {
      color: #257FA4;
    }

    span {
      font-family: "Str-Headline-Bold", sans-serif;
    }
}

.tab-solicitalo {
  p {
    color: #444444;
    font-family: "Str-Microtext-Regular", sans-serif;
    margin-bottom: 0px;

    @media #{$mq-tablet3} {
      text-align: left;
    }

    @media #{$mq-tablet2} {
      text-align: left;
    }

    @media #{$mq-tablet1} {
      text-align: left;
    }
  }

  &__cards {
    display: flex;
 
    @media #{$mq-tablet3} {
      display: grid;
      grid-template-columns: 1fr;
      gap: 8px;
    }
 
    @media #{$mq-tablet2} {
      display: grid;
      grid-template-columns: 1fr;
      gap: 8px;
    }
 
    @media #{$mq-tablet1} {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
    }
 
    @media #{$mq-mobile} {
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
    }

    &__card {
      background-color: #F6FAFC;
      width: 324px;
      height: 177px;
      margin: auto; 
      text-align: center; 
      padding: 16px;  

      @media #{$mq-tablet3} {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
      }

      @media #{$mq-tablet2} {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
      }

      @media #{$mq-tablet1} {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
      }

      @media #{$mq-mobile} {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
      }

      &__icon {
        border-bottom: 1px solid #DAE9F0;
        padding-bottom: 15px;
        // margin-bottom: 10px;

        @media #{$mq-tablet3} { 
          padding-bottom: 0px;
          border-bottom: none;
        }

        @media #{$mq-tablet2} { 
          padding-bottom: 0px;
          border-bottom: none;
        }

        @media #{$mq-tablet1} { 
          padding-bottom: 0px;
          border-bottom: none;
        }

        @media #{$mq-mobile} {
          padding-bottom: 0px;
          border-bottom: none;
        }
        // @media #{$mq-tablet3} {
        //   border-bottom: none;
        //   border-right: 1px solid #DAE9F0;
        //   padding-right: 12px;
        //   margin-right: 12px;
        //   padding-bottom: 0px;
        //   margin-bottom: 0px;
        // }

        // @media #{$mq-tablet2} {
        //   border-bottom: none;
        //   border-right: 1px solid #DAE9F0;
        //   padding-right: 12px;
        //   margin-right: 12px;
        //   padding-bottom: 0px;
        //   margin-bottom: 0px;
        // }

        // @media #{$mq-tablet1} {
        //   border-bottom: none;
        //   border-right: 1px solid #DAE9F0;
        //   padding-right: 12px;
        //   margin-right: 12px;
        //   padding-bottom: 0px;
        //   margin-bottom: 0px;
        // }

        // @media #{$mq-mobile} {
        //   border-bottom: none;
        //   border-right: 1px solid #DAE9F0;
        //   padding-right: 12px;
        //   margin-right: 12px;
        //   padding-bottom: 0px;
        //   margin-bottom: 0px;
        // }

        img {
          @media #{$mq-tablet3} {
            width: 46px;
          }

          @media #{$mq-tablet2} {
            width: 46px;
          }

          @media #{$mq-tablet1} {
            width: 46px;
          }
        }
      }

      &__text {
        margin-top: 10px;

        @media #{$mq-tablet3} {
          height: 63px;
          border-left: 1px solid #DAE9F0;
          margin-top: 0px;
          margin-left: 10px;
          padding-left: 10px;
          display: flex;
          align-items: center;
          text-align: left;
        }
  
        @media #{$mq-tablet2} {
          height: 46px;
          border-left: 1px solid #DAE9F0;
          margin-top: 0px;
          margin-left: 10px;
          padding-left: 10px;
          display: flex;
          align-items: center;
          text-align: left;
        }
  
        @media #{$mq-tablet1} {
          height: 63px;
          border-left: 1px solid #DAE9F0;
          margin-top: 0px;
          margin-left: 10px;
          padding-left: 10px;
          display: flex;
          align-items: center;
          text-align: left;
        }
  
        @media #{$mq-mobile} {
          height: 63px;
          border-left: 1px solid #DAE9F0;
          margin-top: 0px;
          margin-left: 10px;
          padding-left: 10px;
          display: flex;
          align-items: center;
          text-align: left;
        }

      }

    }
  }
}

.tab-simulador {
  color: #444444;
  p {
    font-family: "Str-Microtext-Regular", sans-serif;
    margin-bottom: 28px;

  }
}