.section-contact {
	background-color: #F5F9FB;
	padding: 48px 0px;
	text-align: center;

	@media (min-width: 768px) {
		padding: 64px 0px;
		text-align: start;
	}

	&__body {
		display: flex;
		flex-direction: column;

		@media (min-width: 768px) {
			flex-direction: row;
			justify-content: space-between;
		}

		&__content {
			color: #444444;

			&__title {
				font-size: 28px;
				color: #222222;
				font-family: "Str-Headline-Bold", sans-serif;
				line-height: 32px;

			}

			&__description {
				margin: 24px auto;
				font-size: 18px;
				line-height: 24px;

				font-family: "Str-Microtext-Regular", sans-serif;

				&__phones {
					
					@media (min-width: 768px) {
						display: flex;
					}

					.phone-detail {
						width: 100%;
						display: flex;
						flex-wrap: wrap;
						justify-content: center;

						@media (min-width: 768px) {
							width: auto;
   						 display: block;
						}

						&:first-child {
							margin-bottom: 16px;
						}

						&:last-child {
							margin-bottom: 17px;
						}

						span {
							display: block;
							margin-bottom: 8px;
							width: 100%;

						}
					}
				}
			}

			span {
				font-size: 12px;
			}

			&__span {
				font-family: 'Str-Microtext-Regular', sans-serif;
				font-weight: 400;
				font-size: 16px;
				padding: 12px 24px;
				border: 1px solid #CC0000;
				display: flex;
				gap: 10px;
				color: #EC0000;
				border-radius: 100px;

				@media (min-width: 768px) {
					border: none;
					color: #222222;
					font-family: 'Str-Microtext-Bold', sans-serif;
					padding: 0px;
				}

				img {
					@media (min-width: 768px) {
						display: none;
					}
				}
				p {
					margin: 0;
				}

				
			}
		}

		&__image {

			img {
				width: auto;
			}
		}
	}


}

.hr-contact{
	height: 46px;
	width: 1px;
	background: #CCCCCC;
	margin: 0px 16px;
	display: none;

	@media (min-width: 768px) {
		display: block;
	}

}