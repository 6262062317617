.container__ProductDropdown {
    padding-top: 64px;
    width: 680px;
    margin: 0 auto;
   
}

@media only screen and (max-width: 700px) {
    .container__ProductDropdown {       
        width: 90%;
       
    }
    .simulator-col-drop{
        width: 100%;
    }
}
