:root {
	--circle-size: clamp(32px, 32px, 32px);
	--spacing: clamp(0.25rem, 2vw, 0.5rem);
  }

		.Modal.open {
			animation: bounce-in 1s ease-in;
		}
	
		.Modal.close {
			animation: bounce-out 1s ease-in;
		}
	
		@keyframes bounce-in {
			0% {
				opacity: 0;
				transform: scale(0.5);
			}
	
			100% {
				opacity: 1;
				transform: scale(1);
			}
		}
	
		@keyframes bounce-out {
			0% {
				opacity: 1;
				transform: scale(1);
			}
	
			100% {
				opacity: 0;
				transform: scale(0.5);
			}
		}

.SwiperSlide-container {
	height: 240px;
}

.insurances-container{
	background-color: #F0F0F0;
	padding: 48px 16px;

	.content-insurance{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 24px;
	}
	.card-insurance{
		border-radius: 12px;
		background-color: #FFFFFF;
		padding: 24px 48px;
		text-align: center;
		max-width: 480px;
		img {
			margin-bottom: 24px;
		}
		span, p{
			color: #222222;
			line-height: 24px;
		}
		span{
			font-family: "Str-Headline-Bold", sans-serif;
			margin-bottom: 16px;
			font-size: 20px;
			font-weight: 700;
			display: block;
			padding: 0 6px;
		}
		p {
			font-family: "Str-Microtext-Regular";
			font-size: 16px;
			font-weight: 400;
			margin: 0;
		}
	}
}



.span-surgir {
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-decoration-skip-ink: none;
	text-underline-position: from-font;
	color: #EC0000 !important;
	cursor: pointer;
	 a {
		color: #EC0000 !important;
	 }
}


.section-benefits {
	padding: 96px 0px;

	&__cards {
		margin-top: 48px;
        .swiper-button-next, .swiper-button-prev{
			top: 30%;
		}
		&__card {
			
			color: #444444;
			font-size: 16px;
			line-height: 24px;
			font-family: "Str-Microtext-Regular", sans-serif;
			height: 220px !important;
			display: flex;
			align-items: top;
			justify-content: center;

			div {
				text-align: center;
			}

			img {
				width: 64px !important;
				height: 64px;
				// margin: auto;
			}
			p {
				width: 184px;
				padding: 0px 8px;
				// margin: auto;
    		margin-top: 12px;
				line-height: 24px;
			}
		}
	}
}

.section-toppings-porc {
	padding: 96px 0px;
	background-color: #F6FAFC;
	// &__carousel-toppings {
	// 	padding-left: 16px;
	// 	display: grid ;
	// 	justify-content: right
	// }

	&__cards {
		display: grid;
		grid-template-columns: auto auto auto;
		gap: 16px;

		&__card {
			width: 350px;
			margin: 0px auto;
			border-radius: 16px;
			background-color: #FFFFFF;
			padding: 16px;
			display: flex;
			&__icon {
				margin-right: 16px;
			}

			&__content {

				h5 {
					font-family: "Str-Headline-Bold", sans-serif;
					color: #222222;
				}
				p {
					font-size: 14px;
					color: #444444;
					font-family: "Str-Microtext-Regular", sans-serif;
					margin-bottom: 0px;
				}
			}
			&__content_acordion {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex: 1;
				gap: 16px;
				h5 {
					font-family: "Str-Headline-Bold", sans-serif;
					color: #222222;
				}
			}
		}
	}

	&__footer {
		margin-top: 36px;
		text-align: center;
		color: #222222;
		font-family: "Str-Microtext-Regular", sans-serif;
		border-top: 1px solid #E2EFF4;
		padding-top: 16px;
		line-height: 24px;

		span {
			color: #23779A;
			text-decoration: underline;
			cursor: pointer;
		}
	}

	&__notice {
		font-size: 14px;
		line-height: 20px;
		color: #727272;
		font-family: 'Str-Microtext-Light';
		text-align: center;
		margin-top: 16px;
	}
}

table, th, td {
    border: none !important;
		margin: 0px;   
		border-top-left-radius: 0px;
}

.table > thead > tr > th {
	vertical-align: middle !important;
	// padding-top: 32px !important;
	padding-bottom: 32px !important;
}

.table > tbody > tr > td {
	border-top: 1px solid #CEDEE7 !important;
	padding-top: 32px !important;
	padding-bottom: 32px !important;
	vertical-align: middle !important;
}

.section-sure {
	padding: 48px 0px;
	background-color: #FFFFFF;

	&__cards {
		margin: 48px auto;
		display: flex;
		flex-wrap: wrap;
		grid-gap: 24px;
		justify-content: center;

		&__card {
			width: 460px;
			border: 1px solid;
			padding: 16px 24px;
			border-radius: 12px;
			text-align: center;
			

			&__text {
				margin-bottom: 0px;
				font-size: 16px;
				line-height: 24px;
				font-family: 'Str-Microtext-Regular';
				color: #000000;
			}

			&__des {
				color: #444444;
				font-family: "Str-Microtext-Regular", sans-serif;
				font-size: 14px;
				font-weight: 100;
			}

			&__title {
				font-size: 24px;
				line-height: 32px;
				font-family: Str-Headline-Bold, sans-serif;
			}
		}

		&__first {
			border-color: #9E3667;
			color: #9E3667;
		}

		&__second {
			border-color: #16999D;
			color: #16999D;
		}

		&__thirth {
			
			border-color: #137E84;
			color: #137E84;
		}

	}
}

.section-plans-porc {
	padding: 48px 0px;
	background-color: #FFFFFF;

	&__link {
		display: flex;
    justify-content: center;

		&__body {
			cursor: pointer;
			width: max-content;
			justify-content: center;
			display: flex;
			align-items: center;
			color:#EC0000;
			h6 {
				font-family: "Str-Microtext-Regular", sans-serif;
				margin-bottom: 0px;
				text-decoration: underline;
				text-decoration-thickness: 1px;
				text-decoration-skip-ink: none;
				text-underline-position: from-font;
			}
			span {
				margin-left: 8px;
			}
		}
	}

	&__table {

		&__head {
			&__title {
				color: #222222;
				font-size: 28px;
				width: 150px;
				font-family: "Str-Headline-Bold", sans-serif;
			}
		}

		&__body {
			&__title {
				display: flex;
				align-items: center;
				color: #222222;
				font-weight: 700;
				font-family: "Str-Microtext-Bold", sans-serif;
				// width: max-content;

				img {
					margin-right: 8px;
				}

				span {
					min-width: fit-content;
				}
			}

			&__content {
				text-align: center;
				font-family: "Str-Microtext-Regular", sans-serif;
				&__title {
					font-size: 14px;
					color: #444444;
				}

				&__first {
					color: #9E3667;
				}

				&__second {
					color: #16999D;
				}
				&__thirth {
					color: #137E84;
				}
			}
		}
	}	

	&__conditions {
		color: #8F8F8F;
		font-family: "Str-Microtext-Regular", sans-serif;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 24px;
	}

	.container {
		padding: 0 16px;
	}
}


.section-information {
  background-color: #FFFFFF;
	padding: 96px 0px;

	&__tabs {
		margin-top: 30px;
		&__tab-requirements {
			border: 1px solid #CCCCCC;
			border-radius: 8px;
			padding: 16px;

			ul {
				padding-left: 1.5rem;
				margin-bottom: 0px;
				font-family: "Str-Microtext-Regular", sans-serif;
				color: #444444;
			}
			li {
				list-style-type: initial;
			}
		}

		&__list-order {
			li {
				list-style-type: auto !important;
			}
		}

		&__list-no-order {
			ul {
				padding-left: 2rem;
				margin-bottom: 0px;
				font-family: "Str-Microtext-Regular", sans-serif;
				color: #444444;
			}
			li {
				list-style-type: initial;
			}
		}

		&__link {
			display: flex;
    	margin-top: 28px;

			&__body {
				cursor: pointer;
				width: max-content;
				justify-content: center;
				display: flex;
				align-items: center;
				color:#EC0000;
				border: none;
				background-color: #FFFFFF;
				h6 {
					margin-bottom: 0px;
					text-decoration: underline;
					text-decoration-thickness: 1px;
					text-decoration-skip-ink: none;
					text-underline-position: from-font;
				}
				span {
					margin-left: 8px;
				}
			}
		}

		
		.accordion-item {
			background-color: #F6FAFC !important;
			border: 1px solid #767676 !important;

		}

		.accordion-button:hover {
			background-color: #ECF5F8 !important;
		}
		
		.accordion-button {
			font-family: "Str-Microtext-Bold", sans-serif !important;
			color: #444444 !important;
			background-color: #F6FAFC;
			border-radius: 8px !important;
			font-size: 16px !important;
		}
		.accordion-body {
			background-color: #FFFFFF !important;
			color: #444444;
			border-radius: 8px;
		}

		.accordion-button::after {
			background-image: url("../../../public/icons/chevron-down.svg") !important;
			display: block !important;
		}
		
		.accordion-button:not(.collapsed)::after {
			background-image: url("../../../public/icons/chevron-up.svg") !important;
			
		}

		.accordion-button:not(.collapsed) {
			color: #444444;
		}
	}

	&__tabs-responsive {
		margin-top: 48px;
		.accordion-button {
			color: #727272;
			font-size: "Str-Headline-Regular";
		}
	}

	.container {
		padding: 0 16px;
	}
}



.section-simulator-credit{
	background-color: #F5F9FB;
	padding: 48px 0px;

	@media (min-width: 768px) {
		padding: 64px 0px;
	}

	&--body {
		display: flex;
		flex-direction: column;
		text-align: center;
		gap: 32px;

		@media (min-width: 768px) {
			flex-direction: row;
			text-align: start;
		}

		&--text {
			flex: 1;

			.title-section {
				line-height: 40px;
				
				@media (min-width: 768px) {
					text-align: start;
				}
			}

			p {
				margin: 24px 0px 40px 0px;
				font-family: "Str-Microtext-Regular",sans-serif;
				font-size: 18px;
				line-height: 24px;
			}
		}

		&--buttons {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 16px;

			@media (min-width: 768px) {
				justify-content: initial;
			}

			button {
				background-color: #ffffff;
				color: #EC0000;
				border: 1px solid #EC0000 !important;
				font-weight: 700;
			}
		}

		&--hero {
			flex: 1;

			img {
				width: 100%;
			}
		}
	}


	

}

.width-header-plan {
	width: 184px;
  height: auto;
}

//MOBILE
@media only screen and (max-width: 585px) {
	.table > thead {
		display: none;
	}

	.table > tbody > tr > td {
		border-top: none !important;
		padding: 0px !important;
	}

	.table > tbody > tr > td:nth-child(1) {
		padding-top: 32px !important;
		padding-bottom: 16px !important;
	}

	.table > tbody > tr:nth-child(even) {
		border-bottom: 1px solid #CEDEE7;
	}

	.table > tbody > tr:nth-child(even):last-child {
		border-bottom: none;
	}

	.table > tbody > tr:nth-child(even) > td  {
		padding-bottom: 16px !important;
	}

	.section-benefits {
		padding: 64px 0px;
		&__cards {
			grid-template-columns: 1fr;
			&__card {
				// width: 48px;
			}
		}
		
	}

	.section-plans {
		padding: 64px 0px;
		&__cards {
			margin: 48px auto 12px auto;
			&__card {
				// padding: 8px;
			}
		}
	}

	.section-toppings-porc {
		padding: 48px 0px;
		&__carousel-toppings {
			padding-left: 16px;
		}

		&__footer {
			margin: 17px 18px 0px 18px
		}
	}

	.section-information {
		padding: 64px 0px;
	}
	
}


@media only screen and (min-width: 320px) and (max-width: 575px) {
	.section-toppings-porc {
		padding: 48px 16px;
		&__cards {
			display: grid;
			grid-template-columns: auto;
			&__card {
				width: 100%;
			}
		}
	}

}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.section-toppings-porc {
		padding: 48px 64px;
		&__cards {
			display: grid;
			grid-template-columns: auto;
			&__card {
				width: 480px;
			}
		}
		&__footer {
			margin-top: 16px;
		}
		&__notice {
			padding: 0 30px;
		}
	}

	.title-toppings{
		padding: 0 125px;
	}

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.section-toppings-porc {
		padding: 64px 0px;
		&__cards {
			display: grid;
			grid-template-columns: auto auto;

			&__card {
				width: 342px;
			}
		}
	}

}

@media (min-width: 500px) {

	.section-information {
		.container {
			max-width: 480px;
			padding: 0;
		}
	}

	.section-plans {
		&__cards {
			&__card {
              &__title {
				padding: 0 45px;
			  }
			}
	    }
    }

}

@media (min-width: 768px) {
	.section-toppings-porc{
		&__cards{
			grid-template-columns: auto auto !important;
			&__card__content{
				padding-right: 34px;
			}
		}
		&__footer, &__notice {
			text-align: start;
		}

		&__footer{
			margin-top: 16px;
		}
	}


	.section-information {
		.container {
			max-width: none;
			padding: 0;
		}
		.nav-tabs {
			margin: 48px 0 24px !important;
			padding: 0 8px;
		}
		.tab-content{
			padding:  0 24px 24px;
		}
		&__tabs {
			&__tab-requirements {
				padding: 24px;
			}
		}
	}

	.section-plans {
		padding: 64px 0;
		&__cards {
			justify-content: center;
			flex-wrap: initial;
			&__card {
              &__title {
				padding: 0 5px;
			  }
			  &__text {
				padding: 0 10px;
			  }
			}
	    }

		&__table{
			&__body {
				&__title {
					padding-right: 60px;
				}
			}
		}
    }

	.section-toppings-porc{
		&__cards{
			&__card__content{
				padding-right: 0;
			}
		}
	}
}

@media (min-width: 992px) {
  .container-toppings{
     width: 100%;
	 max-width: 855px;
  }

  .section-information {
	.nav-tabs {
		padding: 0 178px;
	}
	.tab-content{
		padding: 0 172px 24px;
	}
}

.section-plans {
	.container {
		max-width: 855px;
	}
	&__cards {
		&__card {
			max-width: 460px;
			padding: 16px 10px !important;
		  &__title {
			padding: 0 35px;
		  }
		}
	}
}

.width-header-plan {
	width: 275px;
  height: auto;

	h5 {
		padding: 0;
	}
}
}


@media (min-width: 1200px) {

	.section-toppings-porc{
		&__cards{
			display: flex;
			flex-wrap: wrap;
			&__card {
				width: 464px;
			}
		}
	}

	.container-toppings{
		max-width: 945px;
	 }

	 .section-toppings-porc {
		padding: 64px 0px;
	 }

	 .insurances-container{
		padding: 64px 82px;
		.card-insurance{
            max-width: 460px;
			padding: 24px 82px;
			span {
				padding: 0;
			}
		}
	}

	
	.section-information {
		.nav-tabs {
			padding: 0 8px;
		}
		.tab-content{
			padding: 0 24px 24px;
		}
	}

	.section-plans {
		.container {
			max-width: 1104px;
		}
		&__table {
			&__head {
				&__title {
					width: auto;
				}
			}
		}
	}
}

@media (min-width: 1440px) {

  .section-information {
	.nav-tabs {
		padding: 0 48px;
	}
	.container {
		max-width: 1392px
	}
  }

  .section-plans {
	.container {
		max-width: 1344px;
	}
	&__cards {
		&__card {
			&__title {
				// padding: 0 15px;
			}
		}
	}
	.width-header-plan {
		width: 420px;
		height: auto;
		h5 {
			padding: 0 15px;
		}
	}
}
}


@media (min-width: 1500px) {

	.section-plans {
		.container {
			max-width: 1392px;
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.insurances-container{
		.card-insurance{
            max-width: 324px;
		}
	}
}

@media only screen and (min-width: 1025px) and (max-width: 1199px) {
	.insurances-container{
		.card-insurance{
            max-width: 415px;
		}
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.section-toppings-porc {
		padding: 64px 100px;
		&__cards {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			&__card {
				width: 419px;
				margin: 0;
			}

		}
	}

}

@media only screen and (min-width: 2320px) {
  .section-toppings-porc {

		&__carousel-toppings {
			margin-top: 48px;
			justify-content: center
		}
	
	}
}