@import '../../../breakpoints_new';

.rippleVisible {
  color: rgba(34, 34, 34, 0.2);
}

.MuiAccordionSummary-root {
  @media #{$mq-small} {
      padding: 0px 16px !important
  }
}