@font-face {
  font-family: 'Str-Headline-Regular';
  src: url('../SantanderHeadline-Regular.otf');
}

@font-face {
  font-family: 'Str-Headline-Bold';
  src: url('../SantanderHeadline-Bold.otf');
}

@font-face {
  font-family: 'Str-Microtext-Regular';
  src: url('../SantanderMicroText.otf');
}

@font-face {
  font-family: 'Str-Microtext-Bold';
  src: url('../SantanderMicroText-Bd.otf');
}

@font-face{
  font-family: 'Str-Microtext-Light';
  src: url('../SantanderMicroText-Lt.otf')
}