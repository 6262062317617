@import "../../../breakpoints_new";

.surgir-title {
  line-height: 52px;
  font-size: 42px;
  font-family: 'Str-Headline-Regular', sans-serif;
  color: #222222;
  margin: auto;

  @media #{$mq-small} {
    font-size: 28px;
    line-height: 38px;
  }
}

.surgir-subtitle {
  line-height: normal;
  font-family: 'Str-Microtext-Regular', sans-serif;
  color: #222222;
  font-size: 16px;
  margin-top: 16px;
}