.container-button-form {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 2rem 1rem 3rem;

  button {
    max-width: 264px;
    margin: 0 !important;
  }

  @media (min-width: 768px) {
    padding: 48px 0 48px !important;
    gap: 24px;
  }
}