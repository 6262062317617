@import '../../../../breakpoints_new';


.margen-modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .margen-modal {
    width: 100% !important;
  }
}

.modal-content {
  height: auto !important;
}

.scroll-modal {
  overflow-y: scroll !important;
  height: 350px !important;

  &::-webkit-scrollbar {
    width: 5px !important;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: gray;
  }

  @media #{$mq-medium}, #{$mq-small} {
    overflow-y: scroll !important;
    height: 350px !important;
  }
}
